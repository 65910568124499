import React, {ReactElement} from "react";
import {
  Button,
  ButtonBase,
  Card,
  Checkbox, IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {BORDER_RADIUS, PD_MD, PD_SM, PD_XLG, SZ_MD, SZ_SM, SZ_SSM, SZ_XSM} from "./dimens";
import {TableData, TableDataMetadata, tableDataMetadataKey} from "./tabledata";
import {
  BrokenImageOutlined, Favorite, FavoriteBorderOutlined,
  FavoriteOutlined,
  SettingsOutlined,
  Star,
  StarOutlined,
  StarOutlineOutlined
} from "@mui/icons-material";
import {BaseApp} from "./BaseApp";
import {Action, CreateActions} from "./types";
import {StyledBoxColumn, StyledBoxRow} from "./StyledComponents";
import {findIcon} from "./icons";
import Markdown from "react-markdown";
import {brightPaletteColors} from "./colors";
import {md5_code} from "./md5";

export type DataTableRowProps<T, D> = {
  data: T,
  row: D,
  contentItemsMap: Map<string, TableDataContentItem>,
  disableSelection?: boolean,
  onTableCellChanged?: (data: T, cellId: string, value: any) => void,
  onTableDataSelected: (data: T) => void,
  onMultiSelectionChanged: (id: string) => void,
}

type DataTableRowState = {}

class DataTableRowView<T, D extends TableDataRow> extends React.Component<DataTableRowProps<T, D>, DataTableRowState> {

  constructor(props: DataTableRowProps<T, D>, context: any) {
    super(props, context);
  }

  render() {
    const data = this.props.data;
    const row = this.props.row;
    return <>
      <TableRow hover style={{position: "relative"}}>
        {Object.getOwnPropertyNames(row).map(value => {
          const cell = row[value];
          const contentItem = this.props.contentItemsMap.get(value);
          if (contentItem.metadata.type === "profile_photo") {
            return <TableCell style={{width: 0, paddingTop: 0, paddingBottom: 0}}>
              <Card style={{width: SZ_SM, height: SZ_SM, flexShrink: 0}}>
                <img src={cell || BaseApp.CONTEXT.getAppConfig().defaultUserImage}
                     style={{width: "100%", height: "100%"}}/>
              </Card>
            </TableCell>;
          } else if (contentItem.metadata.type === "icon") {
            const IconType = findIcon(cell) || BrokenImageOutlined;
            return <TableCell style={{width: 0, paddingLeft: PD_SM, paddingRight: PD_SM}}>
              <IconType style={{width: SZ_XSM, height: SZ_XSM, flexShrink: 0}}/>
            </TableCell>;
          } else if (contentItem.metadata.type === "checkbox") {
            return <TableCell style={{width: SZ_MD, flexGrow: 0, flexShrink: 0}}>
              {this.renderCheckbox(cell, contentItem, row, value, data)}
            </TableCell>;
          } else if (contentItem.metadata.type === "color") {
            return <TableCell style={{width: SZ_MD, flexGrow: 0, flexShrink: 0}}>
              <Button disabled style={{width: SZ_SM, height: SZ_SSM, backgroundColor: cell}}/>
            </TableCell>;
          } else if (contentItem.metadata.type === "actions") {
            const IconType = contentItem.metadata.actionsIcon || SettingsOutlined;
            return <TableCell style={{width: SZ_MD, flexGrow: 0, flexShrink: 0}}>
              <Button disabled={contentItem.metadata.disabled} style={{zIndex: 1000}} onClick={(event) => {
                event.preventDefault();
                let actions: Action[];
                if (Array.isArray(contentItem.metadata.actions)) {
                  actions = contentItem.metadata.actions as Action[];
                } else {
                  actions = (contentItem.metadata.actions as CreateActions)(event, data);
                }
                BaseApp.CONTEXT.showActionsDialog("Actions", actions, data);
              }}>
                {<IconType/>}
              </Button>
            </TableCell>;
          } else if (contentItem.metadata.type === "custom") {
            return <TableCell style={contentItem.metadata.cellStyle}>{cell}</TableCell>;
          } else if (contentItem.metadata.type === "enum") {
            return <TableCell style={{
              ...contentItem.metadata.cellStyle
            }}>
              <StyledBoxRow>
                <Typography style={{
                  overflowX: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  paddingLeft: PD_MD,
                  paddingRight: PD_MD,
                  color: contentItem.metadata.enumColors?.find(kt => kt.key === cell)?.metadata.color,
                  background: contentItem.metadata.enumColors?.find(kt => kt.key === cell)?.metadata.background,
                  borderRadius: BORDER_RADIUS,
                }}>{contentItem.metadata.enumValues?.find(kt => kt.key === cell)?.text}</Typography>
              </StyledBoxRow>
            </TableCell>;
          } else if (contentItem.metadata.type === "rich_text") {
            return <TableCell style={{
              height: SZ_SSM,
              ...contentItem.metadata.cellStyle
            }}>
              <Typography>
                <Markdown className="rich_text_cell">
                  {cell as string}
                </Markdown>
              </Typography>
            </TableCell>;
          } else if (contentItem.metadata.type === "tagged_text") {
            return <TableCell style={{
              height: SZ_SSM,
              ...contentItem.metadata.cellStyle
            }}>
              <Typography>
                {this.renderTaggedText(cell as string)}
              </Typography>
            </TableCell>;
          } else {
            let text;
            if (typeof cell === "string") {
              text = cell as string;
            } else if (cell instanceof Date) {
              const date = cell as Date;
              text = date.toISOString();
            } else {
              text = cell?.toString() || "";
            }
            return <TableCell style={{
              height: SZ_SSM,
              ...contentItem.metadata.cellStyle
            }}>
              <Typography style={{
                overflowX: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                paddingRight: PD_MD,
                ...contentItem.metadata.cellStyle
              }}>
                {text}
              </Typography>
            </TableCell>;
          }
        })}
        {this.props.disableSelection
          ? null
          : <ButtonBase
            style={{position: "absolute", width: "100%", top: 0, left: 0, bottom: 0, right: 0}}
            onClick={() => this.props.onTableDataSelected(data)}/>
        }
      </TableRow>
    </>;
  }

  private renderCheckbox(cell: D, contentItem: TableDataContentItem, row: D, value: string, data: T) {
    let icon = undefined;
    let checkedIcon = undefined;
    switch (contentItem.metadata.checkboxVariant) {
      default:
      case "checkbox":
        break;
      case "star":
        icon = <StarOutlineOutlined/>;
        checkedIcon = <Star/>;
        break;
      case "heart":
        icon = <FavoriteBorderOutlined/>;
        checkedIcon = <Favorite/>;
        break;
    }
    return <Checkbox
      checked={Boolean(cell)} disabled={contentItem.metadata.disabled}
      icon={icon}
      checkedIcon={checkedIcon}
      onChange={(event, checked) => {
        row[value] = checked;
        this.forceUpdate();
        this.props.onTableCellChanged?.(data, contentItem.metadata.cellId, checked);
        if (contentItem.metadata._propertyKey === "__selected") {
          this.props.onMultiSelectionChanged(value);
        }
      }}/>;
  }

  private renderTaggedText(text: string): ReactElement {
    if (!text) {
      return null;
    }
    let transformed: ReactElement[] = [];
    let start, end = -1;
    while ((start = text.indexOf("[", end + 1)) >= 0) {
      transformed.push(<>{text.substring(end + 1, start)}</>);
      if ((end = text.indexOf("]", start + 1)) > 0) {
        const substring = text.substring(start + 1, end);
        transformed.push(<span style={{
          color: brightPaletteColors[md5_code(substring) % brightPaletteColors.length],
          fontWeight: "bold"
        }}>{" " + substring}</span>);
      } else break;
    }
    transformed.push(<>{text.substring(end + 1)}</>);
    return <Typography
      style={{
        overflowX: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }}>
      {transformed}
    </Typography>
  }
}

// Marker interface for table data row objects
// A "style" field in subclasses will be applied to row view.
export interface TableDataRow {

}

export abstract class BaseSelectTableDataRow implements TableDataRow {

  @TableData({name: "-", type: "checkbox", multiSelect: true})
  __selected: boolean = false;
}

export type TableDataSource<T, D extends TableDataRow> = {

  createTableDataRow(): D,

  applyTableDataToRow(data: T, row: D): void,

  onTableDataSelected(data: T): void;

  onTableCellChanged?: (data: T, cellId: string, value: any) => void;
}

export type TableDataContainerSelectModeConfig<T> = {
  initialSelection?: T[],
}

export interface TableDataContainerMultiSelectListener<T> {
  onSelectionChanged(items: T[], exitSelection: () => void): void;
}

export type GroupingMetadata = {
  id: string,
  title?: string,
}

export const DEFAULT_GROUPING: GroupingMetadata = {id: "_default"};

class GroupedData<T, D extends TableDataRow> {

  contentRows: any[] = [];
  selectAll?: boolean;

  constructor(readonly data: T[] = [], readonly groupingMetadata: GroupingMetadata = DEFAULT_GROUPING) {
  }
}

export type TableDataContainerProps<T, D extends TableDataRow> = {
  data: T[],
  groupingFn?: (item: T) => GroupingMetadata,
  source: TableDataSource<T, D>,
  disableSelection?: boolean,
  selectModeConfig?: TableDataContainerSelectModeConfig<T>,
  multiSelectListener?: TableDataContainerMultiSelectListener<T>,
}

type TableDataContentItem = {
  id: string,
  target: any,
  metadata: TableDataMetadata,
}

type TableDataContainerState<T, D extends TableDataRow> = {
  contentItems: TableDataContentItem[];
  groups: GroupedData<T, D>[],
  selectedGroups?: GroupedData<T, D>[],
}

export class TableDataContainer<T, D extends TableDataRow> extends React.Component<TableDataContainerProps<T, D>, TableDataContainerState<T, D>> {

  private readonly contentItemsMap = new Map<string, TableDataContentItem>();

  constructor(props: TableDataContainerProps<T, D>, context: any) {
    super(props, context);
    this.state = {
      contentItems: [],
      groups: [],
    }
  }

  componentDidMount() {
    this.updateContent();
  }

  componentDidUpdate(prevProps: Readonly<TableDataContainerProps<T, D>>, prevState: Readonly<TableDataContainerState<T, D>>, snapshot?: any) {
    if (prevProps.data !== this.props.data || prevProps.source !== this.props.source || prevProps.groupingFn !== this.props.groupingFn) {
      this.updateContent();
    }
  }

  private updateContent() {
    const row = this.props.source.createTableDataRow();
    const contentItems = this.findContentItems(row);
    this.contentItemsMap.clear();
    contentItems.forEach(contentItem => this.contentItemsMap.set(contentItem.id, contentItem));
    const groups: GroupedData<T, D>[] = [];
    if (this.props.groupingFn) {
      const map = new Map<string, GroupedData<T, D>>();
      for (const item of this.props.data) {
        const groupingMetadata = this.props.groupingFn(item) || DEFAULT_GROUPING;
        let groupedData = map.get(groupingMetadata.id);
        if (!groupedData) {
          groupedData = new GroupedData<T, D>([], groupingMetadata);
          map.set(groupingMetadata.id, groupedData);
        }
        groupedData.data.push(item);
        this.updateContentRow(item, row, contentItems, groupedData);
      }
      groups.push(...map.values());
    } else {
      const groupedData = new GroupedData<T, D>(this.props.data);
      this.props.data.forEach(item => {
        this.updateContentRow(item, row, contentItems, groupedData);
      });
      groups.push(groupedData);
    }
    this.setState({
      contentItems: contentItems,
      groups: groups,
    });
  }

  private updateContentRow(item: T, row: D, contentItems: TableDataContentItem[], groupedData: GroupedData<T, D>) {
    const value = {};
    this.props.source.applyTableDataToRow(item, row);
    contentItems.forEach(contentItem => value[contentItem.id] = row[contentItem.metadata._propertyKey]);
    if (this.props.selectModeConfig?.initialSelection && row instanceof BaseSelectTableDataRow) {
      value["__selected"] = this.props.selectModeConfig.initialSelection.includes(item);
    }
    groupedData.contentRows.push(value);
  }

  private findContentItems(object: D): TableDataContentItem[] {
    let contentItems: TableDataContentItem[] = [];
    // if (!object) {
    //   return contentItems;
    // }
    if (object instanceof Array) {
      const array: any[] = object;
      array.forEach(item => contentItems.push(...this.findContentItems(item)));
    } else {
      for (let propertyKey of Object.getOwnPropertyNames(object)) {
        //@ts-ignore
        let metadata: TableDataMetadata = object[tableDataMetadataKey(propertyKey)];
        if (!metadata) {
          continue;
        }
        const contentItem = {id: propertyKey, target: object, metadata: metadata};
        contentItems.push(contentItem);
      }
    }
    return contentItems;
  }

  render() {
    return <StyledBoxColumn
      style={{
        gap: PD_XLG,
        padding: PD_SM,
        listStyleType: "none",
      }}>
      {this.state.groups.map(group => {
        return <Card>
          <Table>
            <TableHead>
              <TableRow>
                {this.state.contentItems.map(value => <TableCell style={{height: SZ_SSM}}>
                  {value.metadata._propertyKey === "__selected" && value.metadata.multiSelect
                    ? <Checkbox disabled={value.metadata.disabled}
                                checked={Boolean(group.selectAll)}
                                onChange={(event, checked) => {
                                  group.contentRows.forEach(item => item[value.id] = checked);
                                  this.onMultiSelectChanged(group, value.id);
                                }}/>
                    : <b>{value.metadata.name}</b>}
                </TableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              {group.contentRows?.map((row, index) => <DataTableRowView
                data={group.data[index]}
                row={row}
                contentItemsMap={this.contentItemsMap}
                disableSelection={Boolean(this.props.selectModeConfig) || this.props.disableSelection}
                onTableCellChanged={(data, cellId, value) => this.props.source.onTableCellChanged(data, cellId, value)}
                onTableDataSelected={data => this.props.source.onTableDataSelected(data)}
                onMultiSelectionChanged={(id: string) => this.onMultiSelectChanged(group, id)}
              />)}
            </TableBody>
          </Table>
        </Card>
      })}
    </StyledBoxColumn>;
  }

  private onMultiSelectChanged(group: GroupedData<T, D>, id: string) {
    let selectAll = true;
    const items: T[] = group.contentRows.reduce((result: T[], currentValue, currentIndex, array) => {
      if (Boolean(currentValue[id])) {
        result.push(group.data[currentIndex]);
      } else {
        selectAll = false;
      }
      return result;
    }, []);
    group.selectAll = selectAll;
    this.setState({
      selectedGroups: this.state.groups?.filter(group => group.selectAll),
    });
    this.props.multiSelectListener?.onSelectionChanged(items,
      () => {
        group.contentRows.forEach(item => item[id] = false);
        group.selectAll = false;
        this.setState({
          selectedGroups: this.state.groups?.filter(group => group.selectAll),
        });
        this.onMultiSelectChanged(group, id);
      });
  }
}
