import React from 'react';
import {FormGenContentItem, FormGenLayoutSource} from "./FormGenContainer";
import {BORDER_RADIUS, PD_SM, PD_XXSM, SZ_MD, SZ_SM, SZ_SSM} from "shared/dimens";
import {Box, Button, Grid, Popover} from "@mui/material";
import {ArrowDropDown, CloseOutlined} from "@mui/icons-material";
import {paletteColors} from "shared/colors";

export function FormGenColorSelect(props: {
  contentItem: FormGenContentItem,
  index: number,
  source: FormGenLayoutSource
}) {
  let value = props.source.getValue(props.contentItem);
  let color = paletteColors.find(color => color === value);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorClick = (name: string) => {
    props.source.setValue(props.contentItem, name);
  }

  const colors = [null, ...paletteColors]
  const open = Boolean(anchorEl);
  const columns = 3;
  const rows = Math.floor((colors.length + columns - 1) / columns);
  return (
    <Box>
      <Button style={{padding: PD_XXSM}} variant="outlined" onClick={handleClick}>
        <Box style={{
          display: "flex",
          height: SZ_SSM,
          gap: PD_SM,
          alignItems: "center"
        }}>
          <Button disabled style={{width: SZ_MD, height: SZ_SSM, backgroundColor: color, borderRadius: BORDER_RADIUS}}>
            {!color
              ? <CloseOutlined/>
              : null}
          </Button>
          <ArrowDropDown/>
        </Box>
      </Button>
      <Popover
        disablePortal
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box style={{
          display: "flex",
          flexDirection: "column",
          width: SZ_MD * columns + PD_SM * (columns + 1),
          height: SZ_SSM * rows + PD_SM * (rows + 1),
          overflowY: "scroll",
        }}>
          <Box style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: PD_SM,
          }}>
            <Grid container spacing={1}>
              {colors.map(color => {
                return <Grid item xs={4}>
                  <Button style={{width: SZ_MD, height: SZ_SSM, backgroundColor: color}} onClick={() => {
                    handleClose();
                    handleColorClick(color);
                  }}>
                    {!color
                      ? <CloseOutlined/>
                      : null}
                  </Button>
                </Grid>;
              })}
            </Grid>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}
