import {
  $KTM,
  $KTS,
  BaseListItem,
  BaseListItemsLoader,
  ColorValue,
  KeyMetadatas,
  KeyTextStrings,
  ListKeysLoader
} from "../../shared/types";
import {JsonObject} from "../../shared/json/json-object";
import {JSON_OBJECT, Type} from "../../shared/json/helpers";
import {md5, md5_uuid} from "../../shared/md5";
import {getMemberAuth} from "../../shared/auth";
import {JsonProperty} from "../../shared/json/json-property";
import {
  black,
  colorDarkGreen,
  colorDarkPink,
  colorPastelGreen,
  colorPastelRed,
  lightGray,
  mediumDarkGray,
  mediumGray,
  white
} from "../../shared/colors";
import {SvgIcon} from "@mui/material";
import {
  BallotOutlined,
  CalendarTodayOutlined,
  DateRangeOutlined,
  EmailOutlined,
  ImageOutlined,
  InsertLinkOutlined,
  LooksOneOutlined,
  PhoneOutlined,
  ScheduleOutlined,
  TitleOutlined
} from "@mui/icons-material";
import {FormGen} from "../../shared/formgen";
import {getProvisioningId} from "../../shared/BaseApp";
import {ProvisioningContext} from "../../shared/database";
import {MimeTypeMap} from "../../shared/mime_types";

@JsonObject()
export class JobProperties {

  @FormGen({name: "Theme color", type: "color"})
  @JsonProperty()
  themeColor: string;

  // @FormGen({name: "Header image", type: "file", fileMimeTypes: [MimeTypeMap.MimeType_image_png, MimeTypeMap.MimeType_image_jpeg]})
  // @JsonProperty()
  // headerImage: string;
}

export enum JobQuestionItemType {
  CHOICE = "choice",
  TEXT = "text",
  DATE = "date",
  DATE_RANGE = "date_range",
  PHONE = "phone",
  EMAIL = "email",
  NUMBER = "number",
  LINK = "link",
  IMAGE = "image",
  DURATION = "duration",
}

type JobQuestionFieldType = {
  displayName: string,
  type: JobQuestionItemType,
  iconType: typeof SvgIcon,
}

export const JOB_QUESTION_FIELD_TYPES: JobQuestionFieldType[] = [
  {
    displayName: "Choice",
    type: JobQuestionItemType.CHOICE,
    iconType: BallotOutlined,
  },
  {
    displayName: "Text",
    type: JobQuestionItemType.TEXT,
    iconType: TitleOutlined,
  },
  {
    displayName: "Date",
    type: JobQuestionItemType.DATE,
    iconType: CalendarTodayOutlined,
  },
  {
    displayName: "Date range",
    type: JobQuestionItemType.DATE_RANGE,
    iconType: DateRangeOutlined,
  },
  {
    displayName: "Phone",
    type: JobQuestionItemType.PHONE,
    iconType: PhoneOutlined,
  },
  {
    displayName: "Email",
    type: JobQuestionItemType.EMAIL,
    iconType: EmailOutlined,
  },
  {
    displayName: "Number",
    type: JobQuestionItemType.NUMBER,
    iconType: LooksOneOutlined,
  },
  {
    displayName: "Link",
    type: JobQuestionItemType.LINK,
    iconType: InsertLinkOutlined,
  },
  {
    displayName: "Image",
    type: JobQuestionItemType.IMAGE,
    iconType: ImageOutlined,
  },
  {
    displayName: "Duration",
    type: JobQuestionItemType.DURATION,
    iconType: ScheduleOutlined,
  },
];

@JsonObject()
export class JobQuestionItem extends BaseListItem {

  @JsonProperty()
  jobId: string;

  @JsonProperty()
  type: JobQuestionItemType;

  @JsonProperty()
  required: boolean;

  @JsonProperty()
  data: any;

  static createNewItemType(creator: string, created: number, jobId: string, type: JobQuestionItemType) {
    const item = new JobQuestionItem(md5_uuid(), creator, created, jobId, type);
    switch (type) {
      case JobQuestionItemType.CHOICE:
        item.data = JSON_OBJECT.serializeObject(new JobQuestionItemChoiceData());
        break;
      case JobQuestionItemType.TEXT:
        item.data = JSON_OBJECT.serializeObject(new JobQuestionItemTextData());
        break;
    }
    return item;
  }

  constructor(id: string, creator: string, created: number, jobId: string, type: JobQuestionItemType) {
    super(id, creator, created);
    this.jobId = jobId;
    this.type = type;
  }

  readData<T>(dataType: Type<T>): T {
    return JSON_OBJECT.deserializeObject(this.data, dataType) as T;
  }
}

@JsonObject()
export class BaseJobQuestionItemData {

  @FormGen({name: "Question", type: "string"})
  @JsonProperty()
  text: string = "Question";
}

@JsonObject()
export class JobQuestionItemChoiceData extends BaseJobQuestionItemData {

  @FormGen({name: "Options", type: "array_string"})
  @JsonProperty()
  options: string[] = [];
}

@JsonObject()
export class JobQuestionItemTextData extends BaseJobQuestionItemData {
}

export class JobQuestionItems extends BaseListItemsLoader<JobQuestionItem> {

  constructor(readonly companyId: string, readonly jobId: string) {
    super({shared: true, overrideProvisioningContext: new ProvisioningContext(companyId)});
  }

  protected basePath(): string {
    return "job_question_items/" + this.jobId;
  }

  protected deserializeItem(value: any): JobQuestionItem {
    return JSON_OBJECT.deserializeObject(value, JobQuestionItem);
  }

  protected serializeItem(item: JobQuestionItem): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: JobQuestionItem, item2: JobQuestionItem): number {
    return item1.created - item2.created;
  }
}

@JsonObject()
export class JobAnswerItem extends BaseListItem {

  @JsonProperty()
  jobId: string;

  @JsonProperty()
  questionItemId: string;

  @JsonProperty()
  type: JobQuestionItemType;

  @JsonProperty()
  required: boolean;

  @JsonProperty()
  data: any;

  constructor(id: string, creator: string, created: number, jobId: string, questionItemId: string, type: JobQuestionItemType) {
    super(id, creator, created);
    this.jobId = jobId;
    this.questionItemId = questionItemId;
    this.type = type;
  }

  readData<T>(dataType: Type<T>): T {
    return JSON_OBJECT.deserializeObject(this.data, dataType) as T;
  }
}

@JsonObject()
export class BaseJobAnswerItemData {
}

@JsonObject()
export class JobAnswerItemChoiceData extends BaseJobAnswerItemData {

}

@JsonObject()
export class JobAnswerItemTextData extends BaseJobAnswerItemData {

  @JsonProperty()
  text: string;
}

export class JobAnswerItems extends BaseListItemsLoader<JobAnswerItem> {

  constructor(readonly companyId: string, readonly jobId: string, readonly answerId: string) {
    super({shared: true, overrideProvisioningContext: new ProvisioningContext(companyId)});
  }

  protected basePath(): string {
    return "job_answer_items/" + this.jobId + "/" + this.answerId;
  }

  protected deserializeItem(value: any): JobAnswerItem {
    return JSON_OBJECT.deserializeObject(value, JobAnswerItem);
  }

  protected serializeItem(item: JobAnswerItem): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: JobAnswerItem, item2: JobAnswerItem): number {
    return item1.created - item2.created;
  }
}

export class JobAnswerIds extends ListKeysLoader {

  constructor(readonly companyId: string, readonly jobId: string) {
    super({shared: true, overrideProvisioningContext: new ProvisioningContext(companyId)});
  }

  protected basePath(): string {
    return "job_answer_items/" + this.jobId;
  }
}

@JsonObject()
export class JobApplicationProfile {

  @FormGen({name: "First name", type: "string"})
  @JsonProperty()
  firstname: string;

  @FormGen({name: "Last name", type: "string"})
  @JsonProperty()
  lastname: string;
}

@JsonObject()
export class JobApplicationContact {

  @FormGen({name: "Email", type: "string"})
  @JsonProperty()
  email: string;

  @FormGen({name: "Phone (optional)", type: "string"})
  @JsonProperty()
  phone: string;
}

@JsonObject()
export class JobApplicationDetails {

  @FormGen({name: "Additional information", type: "text"})
  @JsonProperty()
  additionalInformation: string;

  @FormGen({name: "Resume", type: "file", fileMimeTypes: [MimeTypeMap.MimeType_application_pdf]})
  @JsonProperty()
  resumeFile: string;
}

@JsonObject()
export class JobApplication {

  @FormGen({name: "Profile", type: "object", description: "Basic information about you"})
  @JsonProperty()
  readonly profile = new JobApplicationProfile();

  @FormGen({name: "Contact", type: "object", description: "How should we contact you?"})
  @JsonProperty()
  readonly contact = new JobApplicationContact();

  @FormGen({name: "Details", type: "object", description: "Relevant experience and additional information"})
  @JsonProperty()
  readonly details = new JobApplicationDetails();
}

export enum JobsViewType {
  JOBS_POSTINGS = "jobs_postings",
}

export const JOBS_VIEW_TYPE_KTS = new KeyTextStrings([
  $KTS(JobsViewType.JOBS_POSTINGS, "Jobs - Postings"),
]);

export enum JobStatus {
  DRAFT = "draft",
  OPEN = "open",
  PAUSED = "paused",
  FILLED = "filled",
  CLOSED = "closed",
}

export const JOB_STATUS_KTS = new KeyTextStrings([
  $KTS(JobStatus.DRAFT, "Draft"),
  $KTS(JobStatus.OPEN, "Published"),
  $KTS(JobStatus.PAUSED, "Paused"),
  $KTS(JobStatus.FILLED, "Filled"),
  $KTS(JobStatus.CLOSED, "Closed"),
]);

export const JOB_STATUS_KTM_COLORS = new KeyMetadatas<ColorValue>([
  $KTM<ColorValue>(JobStatus.DRAFT, new ColorValue(black, lightGray)),
  $KTM<ColorValue>(JobStatus.OPEN, new ColorValue(colorDarkGreen, colorPastelGreen)),
  $KTM<ColorValue>(JobStatus.PAUSED, new ColorValue(colorDarkPink, colorPastelRed)),
  $KTM<ColorValue>(JobStatus.FILLED, new ColorValue(white, mediumDarkGray)),
  $KTM<ColorValue>(JobStatus.CLOSED, new ColorValue(white, mediumGray)),
]);

export enum JobType {
  FULL_TIME = "full_time",
  PART_TIME = "part_time",
  CONTRACTOR = "contractor",
  INTERN = "intern",
}

export const JOB_TYPE_KTS = new KeyTextStrings([
  $KTS(JobType.FULL_TIME, "Full-time employee"),
  $KTS(JobType.PART_TIME, "Part-time employee"),
  $KTS(JobType.CONTRACTOR, "Contractor"),
  $KTS(JobType.INTERN, "Intern"),
]);

export enum SalaryUnit {
  DAY = "day",
  HOUR = "hour",
  MONTH = "month",
  WEEK = "week",
  YEAR = "year",
}

export const SALARY_UNIT_KTS = new KeyTextStrings([
  $KTS(SalaryUnit.DAY, "Day"),
  $KTS(SalaryUnit.HOUR, "Hour"),
  $KTS(SalaryUnit.MONTH, "Month"),
  $KTS(SalaryUnit.WEEK, "Week"),
  $KTS(SalaryUnit.YEAR, "Year"),
]);

@JsonObject()
export class Job extends BaseListItem {

  @JsonProperty()
  readonly companyId: string;

  @FormGen({name: "Job title", type: "string"})
  @JsonProperty()
  title: string;

  @FormGen({name: "Job type", type: "enum", enumValues: JOB_TYPE_KTS.values})
  @JsonProperty()
  type: string;

  @JsonProperty()
  status: string = JobStatus.DRAFT;

  @FormGen({name: "Overview", type: "string", description: "Give a short overview of the job"})
  @JsonProperty()
  overview: string;

  @JsonProperty()
  salaryRangeMinimum: number;

  @JsonProperty()
  salaryRangeMaximum: number;

  @FormGen({name: "Per", type: "enum", enumValues: SALARY_UNIT_KTS.values})
  @JsonProperty()
  salaryUnit: string;

  @FormGen({
    name: "Description",
    type: "rich_text",
    description: "Describe the role including duties, requirements and desired skills. Use headers to create sections and make your post easier to read for applicants."
  })
  @JsonProperty()
  description: string;

  @JsonProperty()
  properties: JobProperties = new JobProperties();

  static createNew(companyId: string): Job {
    return new Job(md5_uuid(), getMemberAuth().getMemberId(), Date.now(), companyId);
  }

  constructor(id: string, creator: string, created: number, companyId: string) {
    super(id, creator, created);
    this.companyId = companyId;
  }
}

export class Jobs extends BaseListItemsLoader<Job> {

  private static instance: Jobs;

  static getInstance(): Jobs {
    if (!this.instance) {
      this.instance = new Jobs(getProvisioningId());
    }
    return this.instance;
  }

  constructor(readonly companyId: string) {
    super({shared: true, overrideProvisioningContext: new ProvisioningContext(companyId)});
  }

  protected basePath(): string {
    return "jobs";
  }

  async addListItem(item: Job, ...rels): Promise<void> {
    if (item.companyId !== this.companyId) {
      throw new Error("Invalid job company id");
    }
    return super.addListItem(item, ...rels);
  }

  protected deserializeItem(value: any): Job {
    return JSON_OBJECT.deserializeObject(value, Job);
  }

  protected serializeItem(item: Job): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: Job, item2: Job): number {
    return 0;
  }
}