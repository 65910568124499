import {DW_LG} from "shared/dimens";
import {App} from "app/App";
import React from "react";
import {StyledBoxColumn} from "../../shared/StyledComponents";
import {AccountBoxOutlined, PauseCircleOutlineOutlined, PlayCircleOutlineOutlined} from "@mui/icons-material";
import {Action} from "../../shared/types";
import {PathProps} from "../../index";
import {Job, Jobs, JobStatus} from "./types";
import {DIALOG_FLAG_DISABLE_BACKDROP_CLICK, DIALOG_FLAG_SHOW_CLOSE} from "../../shared/BaseApp";
import {DialogFragment, PageFragment, PageFragmentProps} from "../../shared/PageFragment";
import {FormGenContainer, FormGenContainerMode} from "../../shared/FormGenContainer";
import {JobPublishViaFragment} from "./JobPublishViaFragment";

export function onManageJob(path: PathProps, job: Job) {
  path.navigate("/jobs" + (job ? "/" + job.id : ""));
}

export function getPublishActions(job: Job) {
  const actions: Action[] = [];
  if (job.status === JobStatus.DRAFT || job.status === JobStatus.PAUSED) {
    actions.push(new Action("Publish", () => {
      showPublishDialog(job);
    }, PlayCircleOutlineOutlined).setVariant("contained"));
  }
  if (job.status === JobStatus.OPEN) {
    actions.push(new Action("Pause", () => {
      showPauseDialog(job);
    }, PauseCircleOutlineOutlined).setVariant("contained"));
  }
  return actions;
}

function showPublishDialog(job: Job) {
  App.CONTEXT.showCustomDialog("Publish",
    <StyledBoxColumn>
      <JobPublishViaFragment job={job}/>
    </StyledBoxColumn>,
    new Action("Publish", () => {
      job.status = JobStatus.OPEN;
      Jobs.getInstance().addListItem(job);
      App.CONTEXT.hideDialog();
    }), null, true);
}

function showPauseDialog(job: Job) {
  App.CONTEXT.showCustomDialog("Pause",
    <StyledBoxColumn>
      <JobPublishViaFragment job={job}/>
    </StyledBoxColumn>,
    new Action("Pause", () => {
      job.status = JobStatus.PAUSED;
      Jobs.getInstance().addListItem(job);
      App.CONTEXT.hideDialog();
    }), null, true);
}

export class EditJobHelper {

  static addJob() {
    App.CONTEXT.showDialog(
      {flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_DISABLE_BACKDROP_CLICK},
      () => <EditJobFragment onJobSaved={job => App.CONTEXT.hideDialog()}/>);
  }

  static editJob(path: PathProps, job: Job) {
    App.CONTEXT.showDialog(
      {flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_DISABLE_BACKDROP_CLICK},
      () => <EditJobFragment path={path} edit={job} onJobSaved={job => App.CONTEXT.hideDialog()}/>);
  }
}

type EditJobFragmentProps = PageFragmentProps & {
  edit?: Job,
  existing?: boolean,
  onJobSaved: (job: Job) => void,
}

class EditJobFragment extends DialogFragment<EditJobFragmentProps> {

  private readonly jobs = Jobs.getInstance();
  private readonly job = this.props.edit ? this.props.edit : Job.createNew(this.jobs.companyId);

  protected onCreateState() {
    return {
      ...super.onCreateState(),
      title: this.props.edit ? "Edit" : "Add",
    };
  }

  protected styleFlags(): number {
    return PageFragment.STYLE_ACTION_PANEL_TYPE_BUTTON_FLAG | PageFragment.STYLE_TOOLBAR_TYPE_FLAG;
  }

  protected getToolbar(toolbarModeId: string | null): React.ReactElement {
    if (this.props.edit) {
      return this.renderToolbarButtonsInToolbarContainer([
        new Action("Manage Job", () => {
          this.hideDialog();
          onManageJob(this.props.path, this.job);
        }, AccountBoxOutlined),
      ]);
    }
    return null;
  }

  protected getActionButtonText(): string {
    return this.props.edit ? "Save" : "Add";
  }

  onActionButtonClicked() {
    this.addJob().then(() => this.props.onJobSaved(this.job));
  }

  private async addJob() {
    await this.jobs.addListItem(this.job);
  }

  renderContent(): React.ReactElement | null {
    return <StyledBoxColumn style={{display: "flex", width: DW_LG}}>
      <FormGenContainer
        mode={this.props.edit ? FormGenContainerMode.EDIT : FormGenContainerMode.CREATE}
        content={this.job}/>
    </StyledBoxColumn>;
  }
}
