import {DialogFragment, DialogFragmentProps, DialogFragmentState, PageFragment} from "../../shared/PageFragment";
import {
  BaseJobAnswerItemData,
  BaseJobQuestionItemData,
  Job,
  JobAnswerIds,
  JobAnswerItem,
  JobAnswerItemChoiceData,
  JobAnswerItems,
  JobAnswerItemTextData, JobApplication, JobApplicationContact,
  JobQuestionItem,
  JobQuestionItemChoiceData,
  JobQuestionItems,
  JobQuestionItemTextData,
  JobQuestionItemType,
  Jobs,
  JobStatus
} from "./types";
import React, {Component, createRef, ReactElement, RefObject} from "react";
import {
  StyledBoxColumn, StyledBoxColumnWithLabel,
  StyledBoxRow,
  StyledContainer,
  StyledHorizontalDivider,
  StyledSpan
} from "../../shared/StyledComponents";
import {Button, Card, Grid, IconButton, Link, Radio, TextareaAutosize, TextField, Typography} from "@mui/material";
import {PD_MD, PD_XLG, PD_XXLG, SZ_JUMBO, SZ_SSM} from "../../shared/dimens";
import {App} from "../App";
import {Action, ActionBase, ActionGroup, ActionGroupMode} from "../../shared/types";
import {
  ArrowBackIosNewOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined, BusinessCenterOutlined,
  ComputerOutlined,
  PhoneOutlined
} from "@mui/icons-material";
import {JSON_OBJECT} from "../../shared/json/helpers";
import {BaseApp, DIALOG_FLAG_DISABLE_BACKDROP_CLICK, DIALOG_FLAG_SHOW_CLOSE} from "../../shared/BaseApp";
import {HtmlFragment} from "../../shared/HtmlFragment";
import {AboutFragment} from "../../shared/AboutFragment";
import fyneappsLogotype from "fyneapps-shared/res/fyneapps_logotype.png";
import {md5_uuid} from "../../shared/md5";
import {JobStatusView} from "../JobStatusView";
import {BaseViewFragment, BaseViewFragmentProps, BaseViewFragmentState, ViewMode} from "./BaseViewFragment";
import Markdown from "react-markdown";
import {mediumDarkGray} from "../../shared/colors";
import {FormGenContainer} from "../../shared/FormGenContainer";

type BaseQuestionItemViewProps = {
  questionItem: JobQuestionItem,
  readonly?: boolean,
  answerItem?: JobAnswerItem,
}

abstract class BaseQuestionItemView<T extends BaseJobQuestionItemData, V extends BaseJobAnswerItemData, P extends BaseQuestionItemViewProps = BaseQuestionItemViewProps, S = {}> extends Component<P, S> {

  constructor(props: P, context: any) {
    super(props, context);
    this.state = this.onCreateState();
  }

  protected onCreateState(): S {
    return {} as S;
  }

  render(): ReactElement {
    const data = this.readQuestionItemData();
    return <StyledBoxColumn style={{marginTop: PD_XLG}}>
      <Typography style={{fontSize: "110%"}}>{data.text}</Typography>
      {this.renderDetails()}
    </StyledBoxColumn>;
  }

  protected abstract readQuestionItemData(): T;

  protected abstract renderDetails(): ReactElement;

  abstract writeAnswerItemData(): V;
}

class QuestionItemChoiceView extends BaseQuestionItemView<JobQuestionItemChoiceData, JobAnswerItemChoiceData> {

  protected readQuestionItemData(): JobQuestionItemChoiceData {
    return JSON_OBJECT.deserializeObject(this.props.questionItem.data, JobQuestionItemChoiceData);
  }

  protected renderDetails() {
    return <>
      <StyledBoxRow style={{alignItems: "center"}}>
        <Radio/>
        <Typography>Option 1</Typography>
      </StyledBoxRow>
      <StyledBoxRow style={{alignItems: "center"}}>
        <Radio/>
        <Typography>Option 2</Typography>
      </StyledBoxRow>
    </>;
  }

  writeAnswerItemData(): JobAnswerItemChoiceData {
    return undefined;
  }
}

class QuestionItemTextView extends BaseQuestionItemView<JobQuestionItemTextData, JobAnswerItemTextData, BaseQuestionItemViewProps, {
  text?: string
}> {

  protected onCreateState(): { text?: string } {
    let text;
    const data = this.props.answerItem?.data;
    if (data) {
      text = JSON_OBJECT.deserializeObject(data, JobQuestionItemTextData)?.text;
    }
    return {
      text: text,
    };
  }

  protected readQuestionItemData(): JobQuestionItemTextData {
    return JSON_OBJECT.deserializeObject(this.props.questionItem.data, JobQuestionItemTextData);
  }

  protected renderDetails() {
    return <>
      <TextField
        disabled={this.props.readonly}
        value={this.state.text}
        onChange={event => this.setState({text: event.target.value})}
        size="small" placeholder={"Your answer..."}/>
    </>;
  }

  writeAnswerItemData(): JobAnswerItemTextData {
    const text = this.state.text?.trim();
    if (!text) {
      return null;
    }
    const data = new JobAnswerItemTextData();
    data.text = text;
    return data;
  }
}

export type ViewJobFragmentProps = BaseViewFragmentProps & {
  jobId?: string,
}

enum AnswerStatus {
  INPUT,
  SAVING,
  SAVED,
  ERROR,
  LOADING,
  VIEW,
}

enum ContentInputView {
  LISTING = "listing",
  APPLICATION = "application",
}

type ViewJobFragmentState = BaseViewFragmentState & {
  job: Job,
  questionItems: JobQuestionItem[],
  answerIds?: string[],
  currentAnswerIndex?: number,
  answerStatus: AnswerStatus,
  answerItems?: JobAnswerItem[],
  contentInputView: ContentInputView,
}

export class ViewJobFragment extends BaseViewFragment<ViewJobFragmentProps, ViewJobFragmentState> {

  protected onCreateState(): ViewJobFragmentState {
    return {
      ...super.onCreateState(),
      answerStatus: AnswerStatus.INPUT,
      contentInputView: ContentInputView.LISTING,
    };
  }

  componentDidUpdate(prevProps: Readonly<ViewJobFragmentProps>, prevState: Readonly<ViewJobFragmentState>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if (prevState.currentAnswerIndex !== this.state.currentAnswerIndex) {
      this.loadAnswers();
    }
  }

  private async loadAnswers() {
    this.setState({
      answerStatus: AnswerStatus.LOADING,
    });
    this.setState({
      answerItems: await new JobAnswerItems(this.state.companyId, this.state.job.id, this.state.answerIds[this.state.currentAnswerIndex]).getOrLoadListItems(),
      answerStatus: AnswerStatus.VIEW,
    });
  }

  protected renderToolbarPreview(): React.ReactElement {
    return <>
      <StyledSpan/>
      {this.renderInToolbarContainer([
        <JobStatusView job={this.state.job}/>,
      ])}
    </>;
  }

  protected renderToolbarAnswers(): React.ReactElement {
    return <Typography variant="h6">{(this.state.answerIds?.length || 0) + " Answer(s)"}</Typography>;
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    await super.fetchOnMount(forceReload);
    const jobId = this.props.jobId || this.props.path.params.job_id;
    if (!jobId) {
      return;
    }
    const companyId = this.props.companyId || this.props.path.params.company_id;
    if (!companyId) {
      return;
    }
    this.setState({
      job: await new Jobs(companyId).getOrLoadItem(jobId),
      questionItems: await (new JobQuestionItems(companyId, jobId)).getOrLoadListItems(),
    });
    if (this.props.viewMode === ViewMode.ANSWERS) {
      let answerIds = await new JobAnswerIds(companyId, jobId).getOrLoadListItems();
      this.setState({
        answerIds: answerIds,
      });
      if ((answerIds?.length > 0)) {
        this.setState({
          currentAnswerIndex: 0,
        });
      }
    }
  }

  protected renderContentHead(): React.ReactElement {
    return this.props.viewMode === ViewMode.ANSWERS
      ? this.renderAnswerNavigation()
      : null;
  }

  protected renderContentBody(): React.ReactElement {
    const job = this.state.job;
    if (!job || (!this.props.jobId && job.status !== JobStatus.OPEN)) {
      return this.renderNotFound();
    }
    let rendered = undefined;
    switch (this.state.answerStatus) {
      default:
      case AnswerStatus.INPUT:
        rendered = this.renderContentInput(job);
        break;
      case AnswerStatus.SAVING:
        rendered = this.renderContentSaving(job);
        break;
      case AnswerStatus.SAVED:
        rendered = this.renderContentSaved(job);
        break;
      case AnswerStatus.ERROR:
        rendered = this.renderContentError(job);
        break;
      case AnswerStatus.LOADING:
        rendered = this.renderContentLoading(job);
        break;
      case AnswerStatus.VIEW:
        rendered = this.renderContentView(job);
        break;
    }
    if (!rendered) {
      rendered = this.renderContentError(job);
    }
    return <>
      <StyledBoxColumn style={{padding: PD_XLG, borderTop: job.properties.themeColor + " 8px solid"}}>
        <StyledBoxRow style={{alignItems: "center", marginTop: -32, marginBottom: PD_MD}}>
          <Button
            startIcon={<BusinessCenterOutlined/>}
            onClick={() => this.props.path.navigate("/v/" + this.state.companyId + (this.props.path.location.search || ""))}>View
            all jobs</Button>
        </StyledBoxRow>
        <Typography variant="h4">{job.title}</Typography>
        <Typography>{job.overview}</Typography>
      </StyledBoxColumn>
      <StyledBoxColumn style={{minHeight: SZ_JUMBO}}>
        {rendered}
      </StyledBoxColumn>
    </>;
  }

  private renderContentInput(job: Job): ReactElement {
    switch (this.state.contentInputView) {
      default:
      case ContentInputView.LISTING:
        return this.renderContentInputListing(job);
      case ContentInputView.APPLICATION:
        return this.renderContentInputApplication(job);
    }
  }

  private renderContentInputListing(job: Job): ReactElement {
    const companySettings = this.state.companySettings;
    return <>
      <StyledBoxRow style={{alignItems: "center", padding: PD_MD, marginTop: -24}}>
        <Button variant="contained"
                style={{backgroundColor: companySettings.themeColor, flexGrow: 1}}
                onClick={() => this.setState({contentInputView: ContentInputView.APPLICATION})}>
          Apply
        </Button>
      </StyledBoxRow>
      <StyledHorizontalDivider/>
      <StyledBoxColumn style={{padding: PD_XLG}}>
        <Typography><Markdown>{job.description}</Markdown></Typography>
      </StyledBoxColumn>
      <StyledHorizontalDivider/>
      <StyledBoxRow style={{alignItems: "center", padding: PD_MD}}>
        <Button variant="contained"
                style={{backgroundColor: companySettings.themeColor, flexGrow: 1}}
                onClick={() => this.setState({contentInputView: ContentInputView.APPLICATION})}>
          Apply
        </Button>
      </StyledBoxRow>
    </>;
  }

  private renderContentInputApplication(job: Job): ReactElement {
    const companySettings = this.state.companySettings;
    return <>
      <StyledBoxRow style={{alignItems: "center", padding: PD_MD, marginTop: -24}}>
        {/*This span is need to avoid an animation artifact on the button below */}
        <span style={{width: 0}}/>
        <Button
          startIcon={<ArrowBackIosNewOutlined/>}
          onClick={() => this.setState({contentInputView: ContentInputView.LISTING})}>View listing</Button>
      </StyledBoxRow>
      <StyledHorizontalDivider/>
      <StyledBoxColumn style={{padding: PD_MD}}>
        <FormGenContainer content={new JobApplication()}/>
      </StyledBoxColumn>
      <StyledHorizontalDivider/>
      <StyledBoxColumn style={{padding: PD_XLG, marginTop: -24}}>
        {this.state.questionItems?.map(item => this.renderItem(item))}
      </StyledBoxColumn>
      <StyledHorizontalDivider/>
      <StyledBoxRow style={{alignItems: "center", padding: PD_MD}}>
        <Button variant="contained"
                style={{backgroundColor: companySettings.themeColor, flexGrow: 1}}
                onClick={() => this.onSubmitApplication()}>
          Submit application
        </Button>
      </StyledBoxRow>
    </>;
  }

  private renderContentView(job: Job): ReactElement {
    return <>
      <StyledBoxColumn style={{padding: PD_XLG, marginTop: -24}}>
        {this.state.questionItems?.map(item => this.renderItem(item, true, this.state.answerItems?.find(answerItem => answerItem.questionItemId === item.id)))}
      </StyledBoxColumn>
    </>;
  }

  private renderContentSaving(job: Job): ReactElement {
    return this.renderContentInDefaultContainer(job, this.renderPleaseWait());
  }

  private renderContentSaved(job: Job): ReactElement {
    return this.renderContentInDefaultContainer(job, <>
      <Typography>Your answer has been recorded, thank you.</Typography>
      {/*<Typography><a href={"https://jobs.fyneapps.com"}>Go home</a></Typography>*/}
    </>);
  }

  private renderContentError(job: Job): ReactElement {
    return this.renderContentInDefaultContainer(job, <Typography>Oops. Something went wrong. Please try again
      later.</Typography>);
  }

  private renderContentLoading(job: Job): ReactElement {
    return this.renderContentInDefaultContainer(job, this.renderPleaseWait());
  }

  private renderContentInDefaultContainer(job: Job, element: ReactElement): ReactElement {
    return <StyledBoxColumn style={{flexGrow: 1, alignItems: "center", justifyContent: "center", padding: PD_MD}}>
      {element}
    </StyledBoxColumn>;
  }

  private renderAnswerNavigation(): ReactElement {
    if (!Number.isInteger(this.state.currentAnswerIndex)) {
      return null;
    }
    return <StyledBoxRow style={{alignItems: "center", flexGrow: 1}}>
      <StyledSpan/>
      <IconButton
        disabled={this.state.currentAnswerIndex <= 0}
        onClick={() => this.setState({
          currentAnswerIndex: this.state.currentAnswerIndex - 1,
        })}>
        <ArrowLeftOutlined/>
      </IconButton>
      <Typography>{this.state.currentAnswerIndex + 1} of {this.state.answerIds.length}</Typography>
      <IconButton
        disabled={this.state.currentAnswerIndex >= this.state.answerIds.length - 1}
        onClick={() => this.setState({
          currentAnswerIndex: this.state.currentAnswerIndex + 1,
        })}>
        <ArrowRightOutlined/>
      </IconButton>
      <StyledSpan/>
    </StyledBoxRow>;
  }

  private readonly viewRefs = new Map<string, RefObject<BaseQuestionItemView<any, any>>>();

  private getViewRef(item: JobQuestionItem): RefObject<BaseQuestionItemView<any, any>> {
    let ref = this.viewRefs.get(item.id);
    if (!ref) {
      ref = createRef<BaseQuestionItemView<any, any>>();
      this.viewRefs.set(item.id, ref);
    }
    return ref;
  }

  private renderItem(questionItem: JobQuestionItem, readonly?: boolean, answerItem?: JobAnswerItem): ReactElement {
    switch (questionItem.type) {
      case JobQuestionItemType.CHOICE:
        return <QuestionItemChoiceView
          //@ts-ignore
          ref={this.getViewRef(questionItem)}
          questionItem={questionItem}
          readonly={readonly}
          answerItem={answerItem}/>;
      case JobQuestionItemType.TEXT:
        return <QuestionItemTextView
          //@ts-ignore
          ref={this.getViewRef(questionItem)}
          questionItem={questionItem}
          readonly={readonly}
          answerItem={answerItem}/>;
    }
    return null;
  }

  private onSubmitApplication() {
    const answerId = md5_uuid();
    const result = this.state.questionItems?.map(item => this.saveItem(item, answerId));
    this.setState({
      answerStatus: AnswerStatus.SAVING,
    });
    setTimeout(() => {
      Promise.all(result)
        .then(() => this.setState({
          answerStatus: AnswerStatus.SAVED,
        }))
        .catch(() => this.setState({
          answerStatus: AnswerStatus.ERROR,
        }));
    }, 1000);
  }

  private async saveItem(item: JobQuestionItem, answerId: string): Promise<void> {
    const data = this.getViewRef(item).current?.writeAnswerItemData();
    if (data) {
      const answerItems = new JobAnswerItems(this.state.companyId, item.jobId, answerId);
      const answerItem = new JobAnswerItem(item.id, null, null, item.jobId, item.id, item.type);
      answerItem.data = JSON_OBJECT.serializeObject(data);
      await answerItems.addListItem(answerItem);
    }
  }
}