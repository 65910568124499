import {AppConfig, BaseApp, BaseAppProps, BaseAppState, getLoginCredentials} from "shared/BaseApp";
import React from 'react';
import {createTheme, ThemeOptions} from "@mui/material";
import {DEFAULT_THEME_OPTIONS} from "shared/colors";
import {FIREBASE_CONFIG} from "./consts";
import {
  THEME_COLOR_PRIMARY,
  THEME_COLOR_PRIMARY_CONTRAST,
  THEME_COLOR_SECONDARY,
  THEME_COLOR_SECONDARY_CONTRAST
} from "fyneapps-shared/consts";
import {AbstractMain} from "shared/AbstractMain";
import {FyneappsToolbar} from "fyneapps-shared/FyneappsToolbar";
import {BaseAppPrefs} from "shared/BaseAppPrefs";
import {PluginConfig} from "../shared/types";
import {MembersKey} from "../shared/entities";
import {LoginConfig} from "../shared/Login";
import defaultUserImage from "./res/default_user.png";
import {md5} from "../shared/md5";

export type AppProps = BaseAppProps & {}

type AppState = BaseAppState & {}

export const THEME_OPTIONS: ThemeOptions = {
  ...DEFAULT_THEME_OPTIONS,
  palette: {
    primary: {
      main: THEME_COLOR_PRIMARY,
      contrastText: THEME_COLOR_PRIMARY_CONTRAST,
    },
    secondary: {
      main: THEME_COLOR_SECONDARY,
      contrastText: THEME_COLOR_SECONDARY_CONTRAST,
    },
  }
};

export const THEME = createTheme(THEME_OPTIONS);

function createPluginConfig(podId: string): PluginConfig {
  return {
    app: {
      themeOptions: THEME_OPTIONS,
      firebase: FIREBASE_CONFIG,
    },
    membersKey: MembersKey.from(podId),
    autoLogin: getLoginCredentials(),
  }
}

export abstract class FyneappsMain extends AbstractMain {

  getPluginConfig() {
    return createPluginConfig("fixme");
  }

  protected createHeaderToolbar(): React.ReactElement | null {
    return <FyneappsToolbar path={this.props.path}/>;
  }
}

class AppPrefs extends BaseAppPrefs {

  private static instance: AppPrefs;

  static getInstance() {
    if (!this.instance) {
      this.instance = new AppPrefs();
    }
    return this.instance;
  }
}

export abstract class FyneappsApp extends BaseApp<AppProps, AppState> {

  getAppPrefs<T extends BaseAppPrefs>(): T {
    return AppPrefs.getInstance() as T;
  }

  protected abstract getAppName(): string;

  protected abstract getIconName(): string;

  protected getLoginConfig(): LoginConfig | null | undefined {
    return {
      provisioning: {
        enabled: true,
      },
    };
  }

  onCreateAppConfig(): AppConfig {
    return {
      name: this.getAppName(),
      icon: this.getIconName(),
      logo: this.getIconName(),
      stamp: this.getIconName(),
      defaultUserImage: defaultUserImage,
      theme: THEME,
      privacyUrl: "",
      termsUrl: "",
      loginConfig: this.getLoginConfig(),
      firebaseConfig: {options: FIREBASE_CONFIG},
    };
  }
}
