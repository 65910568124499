import {Box} from "@mui/material";
import React, {ReactElement} from "react";
import {Sidebar, SidebarItem, SidebarItems, SidebarListener, SidebarTab} from "./Sidebar";
import {PathComponent, PathProps} from "../index";
import {PlaceholderFragment} from "./PlaceholderFragment";
import {AppEventListener, BaseApp} from "./BaseApp";
import {DIVIDER, SIDEBAR_WIDTH, SIDEBAR_WIDTH_SM} from "./dimens";
import {Action, MenuOption} from "./types";
import {parseHandle} from "./route_util";
import {lightGray} from "./colors";

export interface PageWithSidebarContainerRenderer {
  renderPageWithSidebarContainerSidebar(): ReactElement;

  renderPageWithSidebarContainerContent(): ReactElement;
}

const SIDEBAR_SIZES = new Map<string, number>([
  ["md", SIDEBAR_WIDTH],
  ["sm", SIDEBAR_WIDTH_SM],
]);

export enum SidebarLocation {
  LEFT = "left",
  RIGHT = "right",
}

export type PageWithSidebarContainerProps = {
  size?: "md" | "sm",
  title?: string,
  style?: any,
  path?: PathProps,
  sidebarHidden?: boolean,
  sidebarLocation?: SidebarLocation,
  sidebarTabs?: SidebarTab[],
  sidebarItems?: SidebarItems,
  disableSidebarItemSelectionFromPath?: boolean,
  sidebarAction?: Action,
  sidebarStyleFlags?: number,
  menuOptions?: MenuOption[],
  onMenuButtonClicked?: (menuOption: MenuOption) => boolean,
  renderer?: PageWithSidebarContainerRenderer,
  showBackButton?: boolean,
}

export type PageWithSidebarContainerState = {
  disableSidebarSelectionFromPath?: boolean,
  selectedSidebarItem?: SidebarItem,
}

export class PageWithSidebarContainer extends React.Component<PageWithSidebarContainerProps, PageWithSidebarContainerState>
  implements AppEventListener, SidebarListener {

  private readonly theme = BaseApp.CONTEXT.getAppConfig().theme;

  constructor(props: PageWithSidebarContainerProps, context: any) {
    super(props, context);
    this.state = {};
  }

  onEvent(eventName: string, ...args) {
    switch (eventName) {
      case "collapse_sidebar":
        this.forceUpdate();
        break;
    }
  }

  componentDidMount() {
    BaseApp.CONTEXT.addEventListener("collapse_sidebar", this);
  }

  componentWillUnmount() {
    BaseApp.CONTEXT.removeEventListener("collapse_sidebar", this);
  }

  static pathComponentFromSidebarItem(item: SidebarItem, containerId: string): PathComponent {
    if (!item.path) {
      return null;
    }
    return {
      path: item.path,
      handle: {containerId: containerId, path: item.path},
      children: item.nestedPaths,
      render: (pathProps: PathProps) => {
        const rendered = item.render?.(pathProps);
        return rendered ? rendered : <PlaceholderFragment/>;
      },
    };
  }

  static disableSidebarSelectionFromPath(): boolean {
    return false;
  }

  static nestedPathsFromSidebarItems(items: SidebarItems): PathComponent[] {
    return items.groups.flatMap(group => group.items).reduce<PathComponent[]>((result: PathComponent[], item: SidebarItem) => {
      const pathComponent = this.pathComponentFromSidebarItem(item, items.containerId);
      if (pathComponent) {
        result.push(pathComponent);
      }
      return result;
    }, []);
  }

  static getDerivedStateFromProps(nextProps: Readonly<PageWithSidebarContainerProps>, prevState: Readonly<PageWithSidebarContainerState>) {
    let sidebarItem;
    if (nextProps.sidebarItems) {
      sidebarItem = PageWithSidebarContainer.selectedSidebarItemFromPath(nextProps.sidebarItems, nextProps.path);
    } else if (nextProps.sidebarTabs) {
      const selectedTab = nextProps.sidebarTabs.find(tab => {
        const sidebarTabItem = PageWithSidebarContainer.selectedSidebarItemFromPath(tab.items, nextProps.path, true);
        if (sidebarTabItem) {
          tab.selected = sidebarTabItem;
        }
        return sidebarTabItem;
      });
      if (selectedTab) {
        sidebarItem = selectedTab.selected;
      } else {
        sidebarItem = PageWithSidebarContainer.selectedSidebarItemFromPath(nextProps.sidebarTabs[0].items, nextProps.path);
      }
    }
    if (sidebarItem && (!prevState.selectedSidebarItem || sidebarItem.path) && prevState.selectedSidebarItem !== sidebarItem) {
      return {
        selectedSidebarItem: sidebarItem,
      };
    }
  }

  static selectedSidebarItemFromPath(items: SidebarItems, pathProps: PathProps, noDefault?: boolean): SidebarItem {
    const handle = pathProps?.matches.find(match => match.handle?.containerId === items.containerId)?.handle;
    const parsedHandlePath = parseHandle(pathProps, handle)?.path;
    let item = items.groups.flatMap(group => group.items).find(value => value.path === parsedHandlePath || value.path === handle?.path);
    if (!item && !noDefault) {
      item = items.groups[0]?.items[0];
    }
    return item;
  }

  protected disableCollapseSidebar(): boolean {
    return false;
  }

  render(): ReactElement {
    // let border = `1px solid rgba(128, 128, 128, 0.2)`;
    let border = null;
    let content = this.state.selectedSidebarItem?.render?.(this.props.path) || this.props.renderer?.renderPageWithSidebarContainerContent();
    if (!content) {
      content = <PlaceholderFragment/>;
    }
    const collapseSidebar = !this.disableCollapseSidebar() && BaseApp.CONTEXT.getCollapseSidebar();
    const sidebarLocation = this.props.sidebarLocation;
    return <Box style={{display: "flex", height: "100%", ...this.props.style}}>
      {!this.props.sidebarHidden && !collapseSidebar && (!sidebarLocation || sidebarLocation === SidebarLocation.LEFT)
        ? this.renderSidebar()
        : null}
      <Box display="flex" flexGrow="1">
        <Box display='flex'
             flexDirection="column"
             style={{
               borderLeft: border,
               borderRight: border,
               marginLeft: 'auto',
               marginRight: 'auto',
               flexGrow: 1,
               zIndex: 1,
               position: "relative",
               backgroundColor: this.theme.palette.background.default,
             }}>
          {content}
        </Box>
      </Box>
      {!this.props.sidebarHidden && (sidebarLocation === SidebarLocation.RIGHT)
        ? this.renderSidebar()
        : null
      }
    </Box>;
  }

  private renderSidebar() {
    const sidebarLocation = this.props.sidebarLocation;
    return <Box style={{
      display: "flex",
      flexDirection: "column",
      width: SIDEBAR_SIZES.get(this.props.size) || SIDEBAR_WIDTH,
      flexShrink: 0,
      borderRight: (!sidebarLocation || sidebarLocation === SidebarLocation.LEFT) ? DIVIDER : null,
      borderLeft: sidebarLocation === SidebarLocation.RIGHT ? DIVIDER : null,
      background: lightGray,
      overflowY: "scroll",
    }}>
      {this.props.sidebarTabs || this.props.sidebarItems
        ? <Sidebar title={this.props.title}
                   styleFlags={this.props.sidebarStyleFlags}
                   tabs={this.props.sidebarTabs || [{
                     id: "default",
                     title: this.props.sidebarItems?.title,
                     items: this.props.sidebarItems,
                     menuOptions: this.props.menuOptions,
                     action: this.props.sidebarAction,
                     selected: this.state.selectedSidebarItem,
                     listener: this,
                   }]}
        />
        : this.props.renderer?.renderPageWithSidebarContainerSidebar()}
    </Box>;
  }

  onMenuButtonClicked(menuOption: MenuOption): boolean {
    return this.props.onMenuButtonClicked?.(menuOption);
  }

  onSelectionChanged(item: SidebarItem) {
    this.setState({
      selectedSidebarItem: item,
    });
  }
}

export class DialogWithSidebarContainer extends PageWithSidebarContainer {

  constructor(props: PageWithSidebarContainerProps, context: any) {
    super(props, context);
    props.sidebarItems?.groups?.flatMap(group => group.items).map(item => item.onClick = () => {
      this.setState({
        selectedSidebarItem: item,
      });
      return true;
    });
  }

  protected disableCollapseSidebar(): boolean {
    return true;
  }
}
