import React, {ReactElement} from "react";
import {Box, ButtonBase, Card, IconButton, Tab, Tabs, Typography, useTheme} from "@mui/material";
import {UserCache, UserProfilePhoto} from "./types";
import {BORDER_RADIUS, DIVIDER, PD_MD, PD_SM, SZ_MD, SZ_SM, SZ_SSM} from "./dimens";
import {Link, Outlet} from "react-router-dom";
import {colorPrimaryContrastText, translucentBlack} from "./colors";
import {BaseApp, DIALOG_FLAG_DISABLE_BACKDROP_CLICK, DIALOG_FLAG_SHOW_CLOSE} from "./BaseApp";
import {UserFragment} from "./UserFragment";
import {TabInfo, TabsContainer, TabsContainerProps, TabsContainerState} from "./TabsContainer";
import {ArrowCircleLeftOutlined, ArrowCircleRightOutlined, QuestionMarkOutlined} from "@mui/icons-material";
import {AboutFragment} from "./AboutFragment";
import {getAuth} from "@firebase/auth";
import {PluginHostFragment} from "./PluginHostFragment";
import {StyledBoxColumn} from "./StyledComponents";
import {findIcon} from "./icons";

const TAB_SIZE = 72;
const ICON_STYLE = {width: TAB_SIZE + 16, height: TAB_SIZE, padding: 0};

export type AppTabsContainerProps<TAB_TYPE> = TabsContainerProps<TAB_TYPE> & {
  logo?: string,
}

export type AppTabsContainerState<TAB_TYPE> = TabsContainerState<TAB_TYPE> & {}

export abstract class AppTabsContainer<TAB_TYPE, P extends AppTabsContainerProps<TAB_TYPE>, S extends AppTabsContainerState<TAB_TYPE>> extends TabsContainer<TAB_TYPE, P, S> {

  private readonly auth = getAuth();
  private readonly theme = BaseApp.CONTEXT.getAppConfig().theme;

  componentDidMount() {
    this.fetchUser();
  }

  componentWillUnmount() {
  }

  private async fetchUser() {
    const user = await UserCache.getInstance().getUser(this.auth.currentUser.uid);
    this.setState({
      user: user,
    });
  }

  render() {
    const toolbar = this.renderToolbar();
    return <Box style={{display: "flex", width: "100%", flexGrow: 1, overflow: "hidden"}}>
      <Box display='flex' alignItems="center" flexDirection='column' width={TAB_SIZE + 16}
           style={{
             paddingLeft: 8,
             paddingRight: 8,
             background: this.theme.palette.primary.main,
             zIndex: 1200,
             position: "relative"
           }}>
        <ButtonBase
          onClick={() => this.props.path.navigate("/")}>
          {this.renderLogo()}
        </ButtonBase>
        <Tabs
          orientation='vertical'
          indicatorColor="secondary"
          textColor="secondary"
          value={this.state.tabPath}
          onChange={(event, value) => this.setState({tabPath: value})}>
          {TabsContainer.expandGroups(this.props.tabs).items.filter(tab => !tab.hidden).map(tab => {
            return this.renderTabButton(tab);
          })}
        </Tabs>
        <span style={{flexGrow: 1}}/>
        {this.renderSecondaryButtons()}
        <IconButton style={{color: colorPrimaryContrastText, width: SZ_MD, height: SZ_MD, opacity: 0.5}}
                    onClick={() => {
                      BaseApp.CONTEXT.setCollapseSidebar(!BaseApp.CONTEXT.getCollapseSidebar());
                      this.forceUpdate();
                    }}>
          {BaseApp.CONTEXT.getCollapseSidebar() ? <ArrowCircleRightOutlined/> : <ArrowCircleLeftOutlined/>}
        </IconButton>
        <Box style={{display: "flex", alignItems: "center", gap: PD_SM, marginBottom: PD_MD}}>
          <Card style={{width: SZ_SM, height: SZ_SM, flexShrink: 0}}>
            <ButtonBase
              onClick={() => BaseApp.CONTEXT.showDialog(
                null,
                () => <UserFragment uid={this.auth.currentUser.uid}/>)}>
              <img src={UserProfilePhoto(UserCache.getInstance().getCachedUser(this.auth.currentUser.uid))}
                   style={{width: "100%", height: "100%"}}/>
            </ButtonBase>
          </Card>
        </Box>
      </Box>
      <Box style={{display: "flex", flexGrow: 1, flexDirection: "column", position: "relative"}}>
        {Boolean(toolbar) ? this.renderToolbarInContainer(toolbar) : null}
        <Outlet/>
        <PluginHostFragment hostId={BaseApp.CONTEXT.getAppConfig().name + "-" + this.props.tabs.containerId}/>
      </Box>
    </Box>;
  }

  private renderLogo(): ReactElement {
    const appConfig = BaseApp.CONTEXT.getAppConfig();
    const logo = this.props.logo || appConfig.logo || "@icon/extension";
    const theme = appConfig.theme;
    if (logo.endsWith(".png")) {
      return <img src={logo} style={{width: SZ_MD, height: SZ_MD}}/>;
    } else if (logo.startsWith("@icon/")) {
      const IconType = findIcon(logo.substring("@icon/".length));
      return <IconType style={{
        width: SZ_MD,
        height: SZ_MD,
        alignSelf: "center",
        borderRadius: BORDER_RADIUS,
        backgroundColor: theme.palette.primary.main,
        color: "white"
      }} viewBox="-6 -6 36 36"/>
    }
    return null;
  }

  protected renderSecondaryButtons(): ReactElement | null {
    return null;
  }

  private renderTabButton(tab: TabInfo<TAB_TYPE>) {
    return <Tab
      icon={this.renderTabIcon(tab)}
      label={tab.text}
      component={Link}
      to={tab.path}
      style={{
        ...ICON_STYLE,
        fontSize: 10,
        color: colorPrimaryContrastText,
        opacity: tab.path === this.state.tabPath ? 1 : 0.5,
        background: tab.path === this.state.tabPath ? translucentBlack : null,
        minWidth: 0,
        borderRadius: BORDER_RADIUS,
        alignItems: "center",
        justifyContent: "center",
      }}/>;
  }

  private renderTabIcon(tab: TabInfo<TAB_TYPE>): ReactElement {
    if (tab.iconType) {
      return <tab.iconType style={{width: 24, height: 24}}/>;
    } else if (tab.iconUrl) {
      return <img src={tab.iconUrl} style={{width: SZ_SSM, height: SZ_SSM, borderRadius: BORDER_RADIUS}}/>
    }
    return <QuestionMarkOutlined/>;
  }

  private renderToolbarInContainer(toolbar: ReactElement): ReactElement {
    return <Box style={{
      width: "100%",
      height: 72,
      display: "flex",
      flexDirection: "column",
      flexShrink: 0,
      justifyContent: "center",
      borderBottom: DIVIDER,
    }}>
      {toolbar}
    </Box>;
  }

  protected renderToolbar(): ReactElement {
    return null;
  }
}
