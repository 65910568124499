import React, {ReactElement} from "react";
import {NotificationsOutlined,} from "@mui/icons-material";
import {TabInfo, Tabs} from "shared/TabsContainer";
import {AppTabsContainer, AppTabsContainerProps, AppTabsContainerState} from "shared/AppTabsContainer";
import {PathComponent} from "index";
import {Box, Button, Typography} from "@mui/material";
import {PD_SM, PD_XXSM} from "shared/dimens";
import {StyledBadge, StyledBoxRow, StyledSpan} from "shared/StyledComponents";
import {AppEventListener, BaseApp} from "./BaseApp";
import {BaseFragment, BaseFragmentProps, BaseFragmentState} from "shared/BaseFragment";
import {PluginConfigProvider, Plugins} from "shared/plugins";
import {CLOUD_STATUS_METADATA, CloudStatus} from "shared/constants";
import {PluginConfig} from "./types";

const ABSTRACT_TAB_ITEMS: TabInfo<string>[] = [
  {
    type: "plugins",
    path: ":plugin_id",
    groupType: "plugins",
    plugins: [],
  },
];

type MainTabsContainerProps = AppTabsContainerProps<string> & {
  title: string,
  headerToolbar: ReactElement,
}

type MainTabsContainerState = AppTabsContainerState<string> & {
  cloudStatus: CloudStatus,
}

class MainTabsContainer extends AppTabsContainer<string, MainTabsContainerProps, MainTabsContainerState> implements AppEventListener{

  componentDidMount() {
    super.componentDidMount();
    BaseApp.CONTEXT.addEventListener("cloud_status", this);
  }

  componentWillUnmount() {
    BaseApp.CONTEXT.removeEventListener("cloud_status", this);
    super.componentWillUnmount();
  }

  onEvent(eventName: string, ...args: any[]) {
    switch (eventName) {
      case "cloud_status":
        this.setState({
          cloudStatus: args[0] as CloudStatus,
        });
        break;
    }
  }

  protected renderToolbar(): React.ReactElement {
    const statusMetadata = CLOUD_STATUS_METADATA.find(metadata => metadata.type === this.state.cloudStatus) || CLOUD_STATUS_METADATA[0];
    return <Box style={{display: "flex", flexDirection: "column", height: 72}}>
      {this.props.headerToolbar}
      <Box style={{
        gap: PD_SM,
        display: "flex",
        paddingLeft: PD_SM,
        paddingRight: PD_SM,
        height: 40,
        alignItems: "center",
        position: "relative",
      }}>
        <Typography variant="h5" style={{
          paddingLeft: PD_SM,
          paddingRight: PD_SM,
          paddingTop: PD_XXSM,
          paddingBottom: PD_XXSM,
          maxWidth: 360,
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis"
        }}>{this.props.title}</Typography>
        <StyledBoxRow style={{padding: PD_SM, alignItems: "flex-end"}}>
          <statusMetadata.iconType/>
          <Typography variant="caption">{statusMetadata.text}</Typography>
        </StyledBoxRow>
        <StyledSpan/>
        <StyledBoxRow style={{marginLeft: PD_XXSM}}>
          <StyledBadge badgeContent={1}>
            <Button onClick={() => this.props.path.navigate("/notifications")}>
              <NotificationsOutlined/>
            </Button>
          </StyledBadge>
        </StyledBoxRow>
      </Box>
    </Box>;
  }
}

export type AbstractMainProps = BaseFragmentProps & {}

type AbstractMainState = BaseFragmentState & {}

export abstract class AbstractMain extends BaseFragment<AbstractMainProps, AbstractMainState> implements PluginConfigProvider {

  private readonly plugins = Plugins.initInstance(BaseApp.CONTEXT.getAppPrefs().getPluginUrls(), this);

  constructor(props: AbstractMainProps, context: any) {
    super(props, context);
    this.state = {}
  }

  private static tabs(): Tabs<string> {
    return {
      items: [
        ...this.appTabs(),
        ...ABSTRACT_TAB_ITEMS,
      ],
      containerId: "main",
    };
  }

  static appTabs(): TabInfo<string>[] {
    return [];
  }

  static nestedPaths(): PathComponent[] {
    return MainTabsContainer.nestedPathsFromTabs(this.tabs());
  }

  abstract getPluginConfig(): PluginConfig;

  private _tabs: Tabs<string>;

  protected async fetchOnMount(): Promise<void> {
    //@ts-ignore
    this._tabs = this.constructor.tabs();
    const tabInfo: TabInfo<string> = this._tabs.items.find(item => item.type === "plugins");
    tabInfo.plugins = await this.plugins.getOrLoadPlugins();
  }

  private headerToolbar: ReactElement;

  protected abstract createHeaderToolbar(): ReactElement | null;

  protected renderContainerContent(): React.ReactElement | null {
    if (this.headerToolbar === undefined) {
      this.headerToolbar = this.createHeaderToolbar();
    }
    const appConfig = BaseApp.CONTEXT.getAppConfig();
    return <MainTabsContainer
      headerToolbar={this.headerToolbar}
      title={appConfig.name}
      logo={appConfig.logo}
      path={this.props.path}
      tabs={this._tabs}
    />;
  }
}
