import {Job, JOB_TYPE_KTS, Jobs, JobStatus} from "./types";
import React, {ReactElement} from "react";
import {StyledBoxColumn, StyledEmpty} from "../../shared/StyledComponents";
import {Button, Grid, MenuItem, Select, Typography} from "@mui/material";
import {PD_XLG} from "../../shared/dimens";
import {ArrowForwardIosOutlined, BusinessCenterOutlined} from "@mui/icons-material";
import {BaseViewFragment, BaseViewFragmentProps, BaseViewFragmentState} from "./BaseViewFragment";

export type ViewJobsFragmentProps = BaseViewFragmentProps & {}

type ViewJobsFragmentState = BaseViewFragmentState & {
  jobs: Job[],
}

export class ViewJobsFragment extends BaseViewFragment<ViewJobsFragmentProps, ViewJobsFragmentState> {

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    await super.fetchOnMount(forceReload);
    const companyId = this.props.companyId || this.props.path.params.company_id;
    if (!companyId) {
      return;
    }
    this.setState({
      jobs: (await new Jobs(companyId).getOrLoadListItems()).filter(job => job.status === JobStatus.OPEN),
    });
  }

  protected renderContentHead(): React.ReactElement {
    return <>
      {/*{this.renderFilterOptions()}*/}
      <Typography variant="h5">Open positions</Typography>
    </>;
  }

  protected renderContentBody(): React.ReactElement {
    return this.state.jobs?.length > 0
      ? <StyledBoxColumn style={{padding: PD_XLG}}>
        {this.state.jobs.map(job => this.renderContentView(job))}
      </StyledBoxColumn>
      : <StyledEmpty emptyConfig={{
        title: "",
        text: "There are currently no open positions. Please check back again later.",
        iconType: BusinessCenterOutlined,
      }}/>
  }

  private renderFilterOptions(): ReactElement {
    return <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={3}>
        <Select value="" size="small" displayEmpty style={{width: "100%"}}>
          <MenuItem value="">
            <em>Location type</em>
          </MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Select value="" size="small" displayEmpty style={{width: "100%"}}>
          <MenuItem value="">
            <em>Location type</em>
          </MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Select value="" size="small" displayEmpty style={{width: "100%"}}>
          <MenuItem value="">
            <em>Location type</em>
          </MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Select value="" size="small" displayEmpty style={{width: "100%"}}>
          <MenuItem value="">
            <em>Location type</em>
          </MenuItem>
        </Select>
      </Grid>
    </Grid>;
  }

  private renderContentView(job: Job): ReactElement {
    return <Button
      style={{textAlign: "start", padding: PD_XLG}}
      endIcon={<ArrowForwardIosOutlined/>}
      onClick={() => this.props.path.navigate("/v/" + job.companyId + "/" + job.id + (this.props.path.location.search || ""))}>
      <StyledBoxColumn style={{width: "100%"}}>
        <Typography variant="h6">{job.title}</Typography>
        <Typography>{JOB_TYPE_KTS.findText(job.type)}</Typography>
      </StyledBoxColumn>
    </Button>;
  }
}