import {PageFragment, PageFragmentProps, PageFragmentState} from "./PageFragment";
import {Action} from "./types";
import {DownloadOutlined, FilterListOutlined, PrintOutlined, RefreshOutlined} from "@mui/icons-material";
import {TableDataContainerMultiSelectListener, TableDataSource} from "./TableDataContainer";
import React, {ReactElement} from "react";
import {FilterPopoverContent} from "./FilterPopoverContent";

// import {jsPDF} from "jspdf";

export type TableDataSourceFragmentProps = PageFragmentProps & {}

export type TableDataSourceFragmentState = PageFragmentState & {
  filterPopoverAnchorEl?: any,
}

export enum TableDataSourceFragmentButtonType {
  REFRESH = "refresh",
  FILTER = "filter",
  DOWNLOAD = "download",
  PRINT = "print",
}

//@ts-ignore
export abstract class TableDataSourceFragment<T, D, P extends TableDataSourceFragmentProps = TableDataSourceFragmentProps, S extends TableDataSourceFragmentState = TableDataSourceFragmentState> extends PageFragment<P, S>
  implements TableDataSource<T, D>, TableDataContainerMultiSelectListener<T> {

  createToolbarActions(): Action[] {
    const actions: Action[] = [];
    const types = this.getTableToolbarButtonTypes();
    if (types.includes(TableDataSourceFragmentButtonType.REFRESH)) {
      actions.push(new Action("Refresh", () => this.reload(true), RefreshOutlined));
    }
    if (types.includes(TableDataSourceFragmentButtonType.FILTER)) {
      actions.push(new Action("Filter", (event) => {
        this.setState({filterPopoverAnchorEl: event.target});
      }, FilterListOutlined));
    }
    if (types.includes(TableDataSourceFragmentButtonType.DOWNLOAD)) {
      actions.push(new Action("Download", () => this.doDownload(), DownloadOutlined));
    }
    if (types.includes(TableDataSourceFragmentButtonType.PRINT)) {
      actions.push(new Action("Print", () => this.doPrint(), PrintOutlined));
    }
    return actions;
  }

  protected getTableToolbarButtonTypes(): TableDataSourceFragmentButtonType[] {
    return [
      TableDataSourceFragmentButtonType.REFRESH,
      // TableDataSourceFragmentButtonType.FILTER,
    ];
  }

  createToolbarPopovers(): ReactElement[] {
    return [
      this.renderPopover("Filter", this.state.filterPopoverAnchorEl, <FilterPopoverContent/>, () => {
        this.setState({filterPopoverAnchorEl: null})
      })
    ]
  }

  protected toHtmlTableString(): string {
    // const data = this.
    return "<table>" +
      "<thead>" +
      "<tr>" +
      "<td>Hello world</td>" +
      // "<td>{%text%}</td>".replace("{%text%}", ) +
      "</tr>" +
      "</thead>"
    // return null;
  }

  private doDownload() {
    // const htmlTable = this.toHtmlTableString();
    // if (!htmlTable) {
    //   return null;
    // }
    // const doc = new jsPDF();
    // doc.html(htmlTable, {callback: doc => doc.save("hello.pdf")});
  }

  private doPrint() {

  }

  onSelectionChanged(items: T[], exitSelection: () => void) {
    this.setState({
      multiSelectItems: items,
    });
    if (!this.props.selectModeConfig) {
      if (items?.length > 0) {
        this.setToolbarMode({
          id: "select", title: items.length + " Selected", onExitButtonClicked: event => {
            exitSelection();
            return null;
          }
        }, items);
      } else {
        this.clearToolbarMode();
      }
    }
  }
}