import {SvgIcon} from "@mui/material";
import {Action, ColorValue, CreateActions, KeyMetadata, KeyTextString} from "./types";

export interface TableDataMetadata {
  cellId?: string,
  name: string;
  type?: "string" | "string_array" | "rich_text" | "tagged_text"| "profile_photo" | "checkbox" | "color" | "actions" | "enum" | "icon" | "custom";

  // "action" only
  actionsIcon?: typeof SvgIcon,
  actions?: Action[] | CreateActions,

  // "enum" only
  enumValues?: KeyTextString[],
  enumColors?: KeyMetadata<ColorValue>[],

  // "checkbox" only
  checkboxVariant?: "checkbox" | "star" | "heart",

  disabled?: boolean,
  cellStyle?: any,
  multiSelect?: boolean,
  multiSelectToolbarId?: string,

  _propertyKey?: string,
}

export const tableDataMetadataKey = (propertyKey: string) => "__tabledata_" + propertyKey + "_metadata";

export function TableData(metadata: TableDataMetadata) {
  return function (target: any, propertyKey: string) {
    metadata._propertyKey = propertyKey;
    target[tableDataMetadataKey(propertyKey)] = metadata;
  };
}
