import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Fab,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Typography
} from "@mui/material";
import React, {ReactElement} from "react";
import {DIVIDER, PAGE_FRAGMENT_WIDTH, PD_MD, PD_SM, PD_XSM, SZ_MD} from "./dimens";
import {TitleBar} from "./TitleBar";
import {StyledBoxColumn, StyledEmpty, StyledListItem} from "./StyledComponents";
import {FabSpec} from "./PageFragment";
import {Action, EmptyConfig, MenuOption, TabOptionItem, TabOptions} from "./types";
import {PathComponent, PathProps} from "../index";
import {black, colorHighlightAlt} from "./colors";
import {Link} from "react-router-dom";
import {createInput} from "./BaseFragment";
import {BaseApp} from "./BaseApp";
import {ExpandMoreOutlined} from "@mui/icons-material";

export type SidebarItemsVariant = "default" | "comfortable" | "condensed" | "custom";

export type SidebarItemGroup = {
  items?: SidebarItem[],
  title?: string,
  variant?: SidebarItemsVariant,
  action?: Action,
  render?: (pathProps: PathProps) => ReactElement, // only used when variant is "custom"
  _asyncInit?: (items: SidebarItem[]) => Promise<SidebarItem[]>,
}

export type SidebarItem = {
  path?: string,
  hidden?: boolean,
  onClick?: () => boolean,
  object?: any,
  title?: string,
  titleSecondary?: string,
  text?: string,
  textSecondary?: string,
  iconUrl?: string,
  iconFile?: string,
  iconType?: typeof SvgIcon,
  iconForegroundColor?: string,
  iconBackgroundColor?: string,
  children?: SidebarItem[],
  accessoryButton?: ReactElement,
  style?: any,
  nestedPaths?: PathComponent[],
  render?: (pathProps: PathProps) => ReactElement, // only used when "path" is not null.
}

export type SidebarItems = {
  title?: string,
  groups: SidebarItemGroup[],
  containerId: string,
  variant?: SidebarItemsVariant,
  listener?: SidebarListener,
}

export type SidebarTab = {
  id: string,
  title?: string,
  toolbar?: ReactElement,
  items: SidebarItems,
  action?: Action,
  selected?: SidebarItem,
  customViewType?: typeof React.Component,
  customViewTypeProps?: any,
  onItemsLoaded?: (items: SidebarItem[]) => number,
  onItemClicked?: (item: SidebarItem, index: number) => boolean, // true if click handled (selectedItem is not changed), false otherwise
  autoSelect?: boolean
  selectionDisabled?: boolean
  emptyConfig?: EmptyConfig,
  fabSpec?: FabSpec,
  menuOptions?: MenuOption[],
  showBackButton?: boolean,
  listener?: SidebarListener,
}

export type SidebarProps = {
  path?: PathProps,
  title?: string,
  styleFlags?: number,
  tabs: SidebarTab[],
}

export interface SidebarListener {
  onSelectionChanged(item: SidebarItem);

  onMenuButtonClicked(menuOption: MenuOption): boolean;
}

type SidebarState = {
  ready?: boolean;
  selectedTabId: string,
}

export class Sidebar extends React.Component<SidebarProps, SidebarState> {

  // Style flags
  private static readonly STYLE_ALWAYS_SHOW_TABS_MASK = 0x1;
  static readonly STYLE_ALWAYS_SHOW_TABS_FLAG = 1 << 0;

  constructor(props: SidebarProps, context: any) {
    super(props, context);
    this.state = {
      selectedTabId: props.tabs[0].id,
    };
  }

  componentDidMount() {
    this.asyncInit()
      .then(() => this.setState({ready: true}));
  }

  private async asyncInit(): Promise<void> {
    for (const tab of this.props.tabs) {
      for (const group of tab.items.groups) {
        if (group._asyncInit) {
          group.items = await group._asyncInit(group.items);
        }
      }
    }
  }

  private styleFlags() {
    return this.props.styleFlags || 0;
  }

  render() {
    const selectedTab = this.props.tabs.find(tab => tab.id === this.state.selectedTabId);
    const title = this.props.title || (this.props.tabs.length === 1 && this.props.tabs[0].title) || (selectedTab.showBackButton ? "Back" : null);
    const toolbar = this.props.tabs?.find(tab => this.state.selectedTabId === tab.id)?.toolbar;
    const hasTitle = Boolean(title);
    return (
      <Box style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        height: "100%",
      }}>
        {hasTitle ? <TitleBar
            text={title} variant="subdecorated"
            tabOptions={this.createToolbarTabOptions()}
            menuOptions={selectedTab.menuOptions}
            onMenuButtonClicked={(menuOption) => selectedTab.listener?.onMenuButtonClicked(menuOption)}
            showBackButton={selectedTab.showBackButton}
            onBackButtonClicked={() => this.onBackButtonClicked()}/>
          : null}
        {toolbar}
        {this.renderContent(selectedTab, hasTitle)}
        <span style={{height: 0, flexGrow: 1}}/>
        {this.renderActionPanel(selectedTab)}
        {this.renderFab(selectedTab.fabSpec)}
      </Box>
    );
  }

  private createToolbarTabOptions(): TabOptions {
    const tabOptions = new TabOptions(this.props.tabs.map(tab => {
      let tabOptionItem = new TabOptionItem(tab.id, tab.title);
      tabOptionItem.setSelected(tab.id === this.state.selectedTabId);
      return tabOptionItem;
    }), tabId => {
      this.setState({
        selectedTabId: tabId,
      });
    });
    if ((this.styleFlags() & Sidebar.STYLE_ALWAYS_SHOW_TABS_MASK) === Sidebar.STYLE_ALWAYS_SHOW_TABS_FLAG) {
      tabOptions.alwaysShow = true;
    }
    return tabOptions;
  }

  private renderActionPanel(selectedTab: SidebarTab): ReactElement {
    if (selectedTab.action) {
      const IconType = selectedTab.action.iconType;
      return <Box style={{
        display: "flex",
        padding: PD_SM,
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        background: BaseApp.CONTEXT.getAppConfig().theme?.palette.background.paper,
        columnGap: PD_SM,
        borderTop: DIVIDER,
      }}>
        <Button
          style={{width: "100%", maxWidth: PAGE_FRAGMENT_WIDTH, margin: "auto"}}
          variant={selectedTab.action.variant || "text"}
          startIcon={IconType ? <IconType/> : null}
          disabled={selectedTab.action.disabled}
          onClick={(event) => selectedTab.action.onClick(event)}>
          {selectedTab.action.text}
        </Button>
      </Box>;
    }
    return null;
  }

  private renderContent(selectedTab: SidebarTab, hasTitle: boolean): ReactElement {
    const sidebarItems = selectedTab.items;
    return (sidebarItems.groups.length > 0 && (Boolean(sidebarItems.groups[0].render) || sidebarItems.groups[0].items?.length) > 0 ?
      <Box className="hidescroll"
           style={{width: "100%", paddingBottom: SZ_MD, overflowY: "scroll"}}>
        {this.renderSidebar(selectedTab, sidebarItems.groups, selectedTab.items.variant)}
      </Box>
      : this.renderEmpty(selectedTab));
  }

  private renderSidebar(selectedTab: SidebarTab, sidebarItemGroups: SidebarItemGroup[], variant: string) {
    return <StyledBoxColumn>
      {sidebarItemGroups.map(group => {
        if (group.variant === "custom") {
          return group.render?.(this.props.path);
        }
        return this.renderSidebarItems(selectedTab, group.items, group.title, group.variant || variant, group.action);
      })}
    </StyledBoxColumn>;
  }

  private renderSidebarItems(selectedTab: SidebarTab, sidebarItems: SidebarItem[], title: string, variant: string, action?: Action) {
    switch (variant) {
      case "condensed":
        return <StyledBoxColumn style={{padding: PD_SM}}>
          {this.renderSidebarItemsCondensed(selectedTab, sidebarItems, title, action)}
        </StyledBoxColumn>;
    }
    return Sidebar.renderSidebarItemsDefaultInternal(selectedTab, sidebarItems, title, action);
  }

  private renderSidebarItemsCondensed(selectedTab: SidebarTab, sidebarItems: SidebarItem[], title: string, action?: Action) {
    return Sidebar.renderSidebarItemsCondensedInternal(selectedTab, sidebarItems, title, action, this);
  }

  static renderSidebarItemsCondensed(selectedTab: SidebarTab, sidebarItems: SidebarItem[], title: string, action?: Action) {
    return this.renderSidebarItemsCondensedInternal(selectedTab, sidebarItems, title, action);
  }

  private static renderSidebarItemsCondensedInternal(selectedTab: SidebarTab, sidebarItems: SidebarItem[], title: string, action?: Action, sidebar?: Sidebar) {
    return <Accordion disableGutters defaultExpanded>
      {title ?
        <AccordionSummary style={{paddingLeft: PD_SM, paddingRight: PD_SM}}
                          sx={{minHeight: "0px", ".MuiAccordionSummary-content": {margin: 0},}}
                          expandIcon={<ExpandMoreOutlined/>}>
          <Box style={{padding: PD_SM}}>
            <Typography style={{textTransform: "uppercase"}} variant="body2"
                        color={BaseApp.CONTEXT.getAppConfig().theme.palette.primary.main}><b>{title}</b></Typography>
          </Box>
        </AccordionSummary>
        : null}
      <AccordionDetails style={{padding: 0}}>
        <StyledBoxColumn>
          <Box style={{display: "flex", flexDirection: "column"}}>
            {sidebarItems.map((item, index) => {
              if (item.hidden) {
                return null;
              }
              const IconType = item.iconType;
              const hasIcon = Boolean(IconType);
              const buttonContent = <>
                {hasIcon
                  ? <ListItemIcon>
                    <IconType style={{color: item.iconForegroundColor}}/>
                  </ListItemIcon>
                  : null}
                <ListItemText primary={item.title} style={{color: black, textAlign: "start"}}/>
              </>;
              const buttonStyle = {
                paddingLeft: PD_SM,
                paddingRight: PD_SM,
                borderRadius: 0,
                background: selectedTab?.selected === item ? colorHighlightAlt : null,
              };
              return <Box style={{display: "flex", flexDirection: "column"}}>
                {/*{index > 0 ? <Divider style={{marginLeft: hasIcon ? PD_XXLG : 0}}/> : null}*/}
                {item.path
                  ? <Button
                    variant="text"
                    to={item.path}
                    component={Link}
                    onClick={() => item.onClick?.() || Sidebar.handleListItemClick(selectedTab, sidebarItems, index)}
                    style={buttonStyle}>
                    {buttonContent}
                  </Button>
                  : <Button
                    variant="text"
                    onClick={() => item.onClick?.() || Sidebar.handleListItemClick(selectedTab, sidebarItems, index)}
                    style={buttonStyle}>
                    {buttonContent}
                  </Button>
                }
              </Box>;
            })}
          </Box>
          {action ?
            <StyledBoxColumn style={{borderTop: DIVIDER, padding: PD_SM}}>
              <Button
                variant="text"
                startIcon={action.iconType ? <action.iconType/> : null}
                disabled={action.disabled}
                onClick={(event) => action.onClick(event)}>
                {action.text}
              </Button>
            </StyledBoxColumn>
            : null}
        </StyledBoxColumn>
      </AccordionDetails>
    </Accordion>
  }

  static renderSidebarCustomCondensed(render: () => ReactElement, title: string, action?: Action) {
    return this.renderSidebarCustomCondensedInternal(render, title, action);
  }

  private static renderSidebarCustomCondensedInternal(render: () => ReactElement, title: string, action?: Action, sidebar?: Sidebar) {
    return <Accordion disableGutters defaultExpanded>
      {title ?
        <AccordionSummary style={{paddingLeft: PD_SM, paddingRight: PD_SM}}
                          sx={{minHeight: "0px", ".MuiAccordionSummary-content": {margin: 0},}}
                          expandIcon={<ExpandMoreOutlined/>}>
          <Box style={{padding: PD_SM}}>
            <Typography style={{textTransform: "uppercase"}} variant="body2"
                        color={BaseApp.CONTEXT.getAppConfig().theme.palette.primary.main}><b>{title}</b></Typography>
          </Box>
        </AccordionSummary>
        : null}
      <AccordionDetails style={{padding: 0}}>
        <StyledBoxColumn>
          <Box style={{display: "flex", flexDirection: "column"}}>
            {render()}
          </Box>
          {action ?
            <StyledBoxColumn style={{borderTop: DIVIDER, padding: PD_SM}}>
              <Button
                startIcon={action?.iconType ? <action.iconType/> : null}
                variant="outlined"
                disabled={action.disabled}
                onClick={(event) => action.onClick(event)}>
                {action.text}
              </Button>
            </StyledBoxColumn>
            : null}
        </StyledBoxColumn>
      </AccordionDetails>
    </Accordion>;
  }

  static renderSidebarItemsDefault(selectedTab: SidebarTab, sidebarItems: SidebarItem[], title: string) {
    return this.renderSidebarItemsDefaultInternal(selectedTab, sidebarItems, title);
  }

  private static renderSidebarItemsDefaultInternal(selectedTab: SidebarTab, sidebarItems: SidebarItem[], title: string, action?: Action) {
    return <Box style={{display: "flex", flexDirection: "column", padding: PD_SM, gap: PD_SM}}>
      {sidebarItems.map((item: SidebarItem, index) => {
        if (item.hidden) {
          return null;
        }
        return <StyledListItem path={item.path}
                               title={item.title}
                               titleSecondary={item.titleSecondary}
                               text={item.text}
                               textSecondary={item.textSecondary}
                               icon={item.iconType}
                               img={item.iconUrl}
                               iconForegroundColor={item.iconForegroundColor}
                               iconBackgroundColor={item.iconBackgroundColor}
                               selected={selectedTab?.selected === item}
                               accessory={item.accessoryButton}
                               onClick={() => item.onClick?.() || this.handleListItemClick(selectedTab, sidebarItems, index)}/>;
      })}
    </Box>;
  }

  private renderEmpty(selectedTab: SidebarTab) {
    let emptyConfig = selectedTab.emptyConfig;
    if (!emptyConfig) {
      return null;
    }
    return <StyledEmpty emptyConfig={emptyConfig}/>;
  }

  private renderFab(fabSpec: FabSpec): ReactElement {
    if (fabSpec) {
      let IconType = fabSpec.iconType;
      let input = createInput(fabSpec.inputSpec);
      return <Fab
        variant={fabSpec.variant}
        color="secondary"
        style={{position: 'absolute', right: PD_SM, bottom: PD_MD}}
        onClick={(event) => fabSpec.onClick?.(event)}>
        {input}
        <IconType/>
        {fabSpec.variant === 'extended' ? <Typography variant="caption" style={{
          marginLeft: PD_XSM,
          marginRight: PD_XSM,
          fontWeight: "bold"
        }}>{fabSpec.text}</Typography> : null}
      </Fab>
    }
    return null;
  }

  private onBackButtonClicked() {
    // if (selectedTab.showBackButton) {
    //   selectedTab.page.popFragment();
    //   this.setState({
    //     selectedIndex: null,
    //   });
    // }
  }

  private static handleListItemClick(selectedTab: SidebarTab, items: SidebarItem[], index: number) {
    if (!selectedTab) {
      return;
    }
    if ((selectedTab.onItemClicked && !selectedTab.onItemClicked(items[index], index)) || (!selectedTab.onItemClicked && !selectedTab.selectionDisabled)) {
      selectedTab.listener?.onSelectionChanged(items[index]);
    }
  }
}
