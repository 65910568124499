import React from 'react';
import {FormGenContentItem, FormGenLayoutSource} from "./FormGenContainer";
import {PD_SM, SZ_SM, SZ_SSM} from "shared/dimens";
import {Box, Button, Grid, Popover} from "@mui/material";
import {ArrowDropDown} from "@mui/icons-material";
import {ICONS} from "shared/icons";

export function FormGenIconSelect(props: { contentItem: FormGenContentItem, index: number, source: FormGenLayoutSource }) {
  let value = props.source.getValue(props.contentItem);
  let icon = ICONS.find(icon => icon.name === value) || ICONS[0];
  const IconType = icon.iconType;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleIconClick = (name: string) => {
    props.source.setValue(props.contentItem, name);
  }

  const open = Boolean(anchorEl);
  return (
    <Box>
      <Button style={{padding: 0}} variant="outlined" onClick={handleClick}>
        <Box style={{
          display: "flex",
          height: SZ_SSM,
          gap: PD_SM,
          alignItems: "center"
        }}>
          <IconType/>
          <ArrowDropDown/>
        </Box>
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box style={{
          display: "flex",
          flexDirection: "column",
          width: 320,
          height: 360,
          overflowY: "scroll",
        }}>
          <Box style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: PD_SM,
            gap: PD_SM
          }}>
            <Grid container spacing={2}>
              {ICONS.map(icon => {
                const IconType = icon.iconType;
                return <Grid item xs={3}>
                  <Button variant="text" style={{height: SZ_SSM}} onClick={() => {
                    handleClose();
                    handleIconClick(icon.name);
                  }}><IconType/></Button>
                </Grid>;
              })}
            </Grid>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}
