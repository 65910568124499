import {
  TableDataSourceFragment,
  TableDataSourceFragmentProps,
  TableDataSourceFragmentState
} from "./TableDataSourceFragment";
import {AbstractListItemsLoader, BaseListItem, OnListItemsListener} from "./types";
import {getMemberAuth} from "./auth";
import {TableDataRow} from "./TableDataContainer";

export abstract class ListTableDataSourceFragment<T extends BaseListItem, D extends TableDataRow, L extends AbstractListItemsLoader<T>, P extends TableDataSourceFragmentProps = TableDataSourceFragmentProps, S extends TableDataSourceFragmentState = TableDataSourceFragmentState> extends TableDataSourceFragment<T, D, P, S>
  implements OnListItemsListener<T> {

  protected readonly memberAuth = getMemberAuth();

  constructor(props: P, context: any, protected loader?: L) {
    super(props, context);
    this.loader = loader;
  }

  setLoader(loader: L) {
    this.loader?.unregisterObserver(this);
    this.loader = loader;
    this.loader?.registerObserver(this);
    this.reload(true);
  }

  componentDidMount() {
    super.componentDidMount();
    this.loader?.registerObserver(this);
  }

  componentWillUnmount() {
    this.loader?.unregisterObserver(this);
  }

  onItemChanged(item: T) {
    this.forceUpdate();
  }

  protected async fetchOnMount(): Promise<void> {
    await this.loader?.loadListItems();
  }
}