import {JsonObject} from "../../shared/json/json-object";
import {FormGen} from "../../shared/formgen";
import {JsonProperty} from "../../shared/json/json-property";
import {Type} from "../../shared/json/helpers";
import {$KTS, DefaultObjectLoader, KeyTextStrings, TypedObject} from "../../shared/types";
import {JOBS_VIEW_TYPE_KTS, JobsViewType} from "../jobs/types";
import {MimeTypeMap} from "../../shared/mime_types";
import {ProvisioningContext} from "../../shared/database";


@JsonObject()
export abstract class BaseSettings extends TypedObject {

  constructor() {
    super(null, null);
  }

  protected basePath(): string | null {
    return "job_settings";
  }

  createDefaultLoader(): DefaultObjectLoader<any> {
    return new DefaultObjectLoader<any>(this.basePath(), this.id, this.getType());
  }
}

@JsonObject()
export class GeneralSettings extends BaseSettings {

  readonly id: string = "general";

  @FormGen({name: "Confirm before deleting", type: "boolean"})
  @JsonProperty()
  confirmBeforeDeleting: boolean = true;

  @FormGen({name: "Move starred tasks to top", type: "boolean"})
  @JsonProperty()
  moveStarredTasksToTop: boolean = true;

  static async load() {
    const obj = new GeneralSettings();
    await obj.load();
    return obj;
  }

  protected getType(): Type<any> {
    return GeneralSettings;
  }
}

@JsonObject()
export class CompanyProfile extends TypedObject {

  readonly id: string = "company_profile";

  @FormGen({name: "Company name", type: "string"})
  @JsonProperty()
  name: string;

  @FormGen({name: "Company website", type: "string"})
  @JsonProperty()
  website: string;

  @FormGen({name: "About your company", type: "rich_text"})
  @JsonProperty()
  about: string;

  @FormGen({
    name: "Company logo",
    type: "file",
    fileMimeTypes: [MimeTypeMap.MimeType_image_png, MimeTypeMap.MimeType_image_jpeg]
  })
  @JsonProperty()
  logo: string;

  @FormGen({name: "Theme color", type: "color"})
  @JsonProperty()
  themeColor: string;

  constructor() {
    super(null, null);
  }

  static async load(overrideProvisioningContext?: ProvisioningContext) {
    const obj = new CompanyProfile();
    await obj.load(false, overrideProvisioningContext);
    return obj;
  }

  createDefaultLoader(): DefaultObjectLoader<any> {
    return new DefaultObjectLoader<any>(this.basePath(), this.id, this.getType(), {shared: true});
  }

  protected getType(): Type<any> {
    return CompanyProfile;
  }
}

@JsonObject()
export class NotificationsSettings extends BaseSettings {

  readonly id = "notifications";

  @FormGen({name: "Email", description: "Get notified when a folder is shared with you.", type: "boolean"})
  @JsonProperty()
  email: boolean;

  static async load() {
    const obj = new NotificationsSettings();
    await obj.load();
    return obj;
  }

  protected getType(): Type<any> {
    return NotificationsSettings;
  }
}

export enum FontSize {
  SMALL = "small",
  DEFAULT = "default",
  LARGE = "large",
}

const FONT_SIZE_KTS = new KeyTextStrings([
  $KTS(FontSize.SMALL, "Small"),
  $KTS(FontSize.DEFAULT, "Default"),
  $KTS(FontSize.LARGE, "Large"),
]);

@JsonObject()
export class PreferencesSettings extends BaseSettings {

  readonly id = "preferences";

  @FormGen({
    name: "Font size",
    sectionTitle: "Display",
    type: "enum",
    description: "Adjust your font size.",
    enumValues: FONT_SIZE_KTS.values,
    enumDefaultKey: FontSize.DEFAULT,
  })
  @JsonProperty()
  fontSize: string;

  @FormGen({
    name: "Default home view",
    sectionTitle: "Behavior",
    separator: "above",
    type: "enum",
    description: "Which view is opened when you open up the app.",
    enumValues: JOBS_VIEW_TYPE_KTS.values,
    enumDefaultKey: JobsViewType.JOBS_POSTINGS,
  })
  @JsonProperty()
  defaultHomeView: string = JobsViewType.JOBS_POSTINGS;

  @FormGen({
    name: "Auto add to Focus",
    type: "boolean",
    description: "When creating new Jobs, always add them to Focus by default."
  })
  @JsonProperty()
  autoAddToFocus: boolean = true;

  static async load() {
    const obj = new PreferencesSettings();
    await obj.load();
    return obj;
  }

  protected getType(): Type<any> {
    return PreferencesSettings;
  }
}

export class Settings {

  private static instance: Settings;

  static getInstance(): Settings {
    return this.instance;
  }

  static async loadInstance() {
    this.instance = new Settings(
      await GeneralSettings.load(),
      await NotificationsSettings.load(),
      await PreferencesSettings.load(),
    );
  }

  constructor(readonly general: GeneralSettings, readonly notifications: NotificationsSettings, readonly preferences: PreferencesSettings) {
  }
}