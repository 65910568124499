import {PageFragment, PageFragmentProps, PageFragmentState} from "../../shared/PageFragment";
import React, {Component, ReactElement} from "react";
import {
  BaseJobQuestionItemData,
  Job,
  JOB_QUESTION_FIELD_TYPES,
  JobAnswerIds,
  JobQuestionItem,
  JobQuestionItemChoiceData,
  JobQuestionItems,
  JobQuestionItemTextData,
  JobQuestionItemType,
  Jobs,
  JobStatus
} from "./types";
import {StyledBoxColumn, StyledBoxRow, StyledContainer, StyledSpan} from "../../shared/StyledComponents";
import {DIVIDER, PD_MD, PD_SM, PD_XLG} from "../../shared/dimens";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonBase,
  Card, FormControlLabel,
  Grid,
  IconButton,
  Radio, RadioGroup,
  Switch,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import {
  AddOutlined,
  ArchiveOutlined,
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  ContentCopyOutlined,
  DeleteOutlined,
  ExpandMoreOutlined,
  LeaderboardOutlined,
  PreviewOutlined
} from "@mui/icons-material";
import {$KTS, Action, KeyTextStrings, ListItemChange, OnListItemsListener} from "../../shared/types";
import {FormGenContainer, FormGenContainerMode} from "../../shared/FormGenContainer";
import {colorRed} from "../../shared/colors";
import {DateUtil} from "../../shared/date_util";
import {App} from "../App";
import {Settings} from "../settings/settings";
import {
  PageWithSidebarContainer,
  PageWithSidebarContainerRenderer,
  SidebarLocation
} from "../../shared/PageWithSidebarContainer";
import {Sidebar} from "../../shared/Sidebar";
import {BaseApp, DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN, getProvisioningId} from "../../shared/BaseApp";
import {THEME_COLOR_PRIMARY} from "../../fyneapps-shared/consts";
import {getMemberAuth} from "../../shared/auth";
import {JSON_OBJECT} from "../../shared/json/helpers";
import {ViewJobFragment} from "./ViewJobFragment";
import {JobStatusView} from "../JobStatusView";
import {getPublishActions} from "./EditJobHelper";
import {ViewMode} from "./BaseViewFragment";
import {TabInfo} from "../../shared/TabsContainer";

function JobContentAddQuestionView(props: {
  focused?: boolean,
  onAddQuestionItemType: (type: JobQuestionItemType) => void
}) {
  if (!props.focused) {
    return null;
  }
  return <Accordion disableGutters style={{padding: PD_SM}}>
    <AccordionSummary
      style={{paddingLeft: PD_SM, paddingRight: PD_SM}}
      sx={{minHeight: "0px", ".MuiAccordionSummary-content": {margin: 0},}}
      expandIcon={<ExpandMoreOutlined/>}>
      <StyledBoxRow style={{padding: PD_SM}}>
        <AddOutlined/>
        <Typography style={{textTransform: "uppercase"}} variant="body2"
                    color={BaseApp.CONTEXT.getAppConfig().theme.palette.primary.main}>
          <b>Add question</b>
        </Typography>
      </StyledBoxRow>
    </AccordionSummary>
    <AccordionDetails style={{padding: 0}}>
      <Grid container spacing={2}>
        {JOB_QUESTION_FIELD_TYPES.map(type => <Grid item xs={4}>
          <Button
            variant="outlined"
            style={{padding: PD_MD, width: "100%"}}
            startIcon={<type.iconType/>}
            onClick={() => props.onAddQuestionItemType(type.type)}>
            {type.displayName}
          </Button>
        </Grid>)}
      </Grid>
    </AccordionDetails>
  </Accordion>;
}

type BaseFocusableViewProps = {
  job: Job,
  onAddQuestionItemType: (type: JobQuestionItemType) => void,
}

type BaseFocusableViewState = {
  focused?: boolean,
}

abstract class BaseFocusableView<P extends BaseFocusableViewProps = BaseFocusableViewProps, S extends BaseFocusableViewState = BaseFocusableViewState> extends Component<P, S> {

  private static readonly instances: BaseFocusableView[] = [];
  private static focusedInstance: BaseFocusableView;

  constructor(props: P, context: any) {
    super(props, context);
    this.state = {} as S;
  }

  componentDidMount() {
    BaseFocusableView.instances.push(this);
  }

  componentWillUnmount() {
    const index = BaseFocusableView.instances.findIndex(instance => instance === this);
    if (index < 0) {
      return;
    }
    BaseFocusableView.instances.splice(index, 1);
  }

  onWillFocus(): void {
  }

  onWillBlur(): void {
  }

  static setFocusedView(view: BaseFocusableView) {
    if (view === this.focusedInstance) {
      return;
    }
    this.focusedInstance?.onWillBlur();
    view?.onWillFocus();
    BaseFocusableView.instances.forEach(instance => {
      instance.setState({
        focused: instance === view,
      });
    });
  }

  render() {
    return <Card>
      <StyledBoxColumn style={{
        gap: 0,
        padding: PD_SM,
        position: "relative",
        border: this.state.focused ? (THEME_COLOR_PRIMARY + " 2px solid") : null
      }}>
        {this.renderContent(Boolean(this.state.focused))}
        <JobContentAddQuestionView
          focused={this.state.focused}
          onAddQuestionItemType={this.props.onAddQuestionItemType}/>
        {!this.state.focused
          ? <Typography variant="caption" style={{opacity: 0.5}}>Tap to edit block or add questions</Typography>
          : null}
        {!this.state.focused
          ? <ButtonBase style={{position: "absolute", left: 0, top: 0, right: 0, bottom: 0}}
                        onClick={() => BaseFocusableView.setFocusedView(this)}/>
          : null}
      </StyledBoxColumn>
    </Card>;
  }

  protected abstract renderContent(focused: boolean);
}

type HeaderViewProps = BaseFocusableViewProps & {
  updateJob: (job: Job) => void,
}

class HeaderView extends Component<HeaderViewProps> {

  render() {
    const job = this.props.job;
    return <>
      <StyledBoxColumn style={{gap: PD_XLG}}>
        <StyledBoxColumn>
          <Typography variant="h6">Is a cover letter required to apply?</Typography>
          <RadioGroup>
            <FormControlLabel value={"yes"} control={<Radio/>} label={"Yes, applicants must submit a cover letter"}/>
            <FormControlLabel value={"optional"} control={<Radio/>}
                              label={"No, submitting a cover letter is optional"}/>
            <FormControlLabel value={"no"} control={<Radio/>}
                              label={"No, applicants will not be asked for their cover letter"}/>
          </RadioGroup>
        </StyledBoxColumn>
        <StyledBoxColumn>
          <Typography variant="h6">Is a resume required to apply?</Typography>
          <RadioGroup>
            <FormControlLabel value={"yes"} control={<Radio/>} label={"Yes, applicants must submit a resume"}/>
            <FormControlLabel value={"optional"} control={<Radio/>} label={"No, submitting a resume is optional"}/>
          </RadioGroup>
        </StyledBoxColumn>
      </StyledBoxColumn>
      <JobContentAddQuestionView
        focused
        onAddQuestionItemType={this.props.onAddQuestionItemType}/>
    </>;
  }
}

type BaseQuestionItemViewProps = BaseFocusableViewProps & {
  item: JobQuestionItem,
}

abstract class BaseQuestionItemView<T extends BaseJobQuestionItemData, P extends BaseQuestionItemViewProps = BaseQuestionItemViewProps> extends BaseFocusableView<P> {

  private readonly jobQuestionItems = new JobQuestionItems(getProvisioningId(), this.props.job.id);

  protected renderContent(focused: boolean) {
    const data = this.readData();
    return <>
      <StyledBoxRow style={{alignItems: "center", borderBottom: DIVIDER, padding: PD_SM, marginTop: -8}}>
        <Typography>Required</Typography>
        <Switch
          defaultChecked={this.props.item.required}
          onChange={(event, checked) => {
            this.props.item.required = checked;
            this.jobQuestionItems.addListItem(this.props.item);
          }}/>
        <StyledSpan/>
        <Tooltip title={"Duplicate"}>
          <IconButton>
            <ContentCopyOutlined/>
          </IconButton>
        </Tooltip>
        <Tooltip title={"Delete"}>
          <IconButton>
            <DeleteOutlined/>
          </IconButton>
        </Tooltip>
        <Tooltip title={"Move up"}>
          <IconButton>
            <ArrowUpwardOutlined/>
          </IconButton>
        </Tooltip>
        <Tooltip title={"Move down"}>
          <IconButton>
            <ArrowDownwardOutlined/>
          </IconButton>
        </Tooltip>
      </StyledBoxRow>
      <FormGenContainer
        mode={focused ? FormGenContainerMode.EDIT : FormGenContainerMode.READ}
        autoSave
        onContainerSave={() => {
          this.writeData(data);
          this.jobQuestionItems.addListItem(this.props.item);
        }}
        content={data}/>
      <StyledBoxColumn style={{padding: PD_MD, marginTop: -16}}>
        {this.renderDetails(focused)}
      </StyledBoxColumn>
    </>;
  }

  protected abstract readData(): T;

  protected abstract writeData(data: T): void;

  protected abstract renderDetails(focused: boolean): ReactElement;
}

class QuestionItemChoiceView extends BaseQuestionItemView<JobQuestionItemChoiceData> {

  protected readData(): JobQuestionItemChoiceData {
    return JSON_OBJECT.deserializeObject(this.props.item.data, JobQuestionItemChoiceData);
  }

  protected writeData(data: JobQuestionItemChoiceData): void {
    this.props.item.data = JSON_OBJECT.serializeObject(data);
  }

  protected renderDetails(focused: boolean) {
    return <>
      <StyledBoxRow style={{alignItems: "center"}}>
        <Radio disabled/>
        <TextField size="small" placeholder={"Option 1"}/>
      </StyledBoxRow>
      <StyledBoxRow style={{alignItems: "center"}}>
        <Radio disabled/>
        <TextField size="small" placeholder={"Option 2"}/>
      </StyledBoxRow>
    </>;
  }
}

class QuestionItemTextView extends BaseQuestionItemView<JobQuestionItemTextData> {

  protected readData(): JobQuestionItemTextData {
    return JSON_OBJECT.deserializeObject(this.props.item.data, JobQuestionItemTextData);
  }

  protected writeData(data: JobQuestionItemTextData): void {
    this.props.item.data = JSON_OBJECT.serializeObject(data);
  }

  protected renderDetails(focused: boolean) {
    return <>
      <TextField size="small" placeholder={"Your answer..."} disabled/>
    </>;
  }
}

type JobApplicationFragmentProps = PageFragmentProps & {
  job: Job,
  updateJob: (job: Job) => void,
}

class JobApplicationFragment extends PageFragment<JobApplicationFragmentProps> implements OnListItemsListener<JobQuestionItem> {

  private readonly jobQuestionItems = new JobQuestionItems(getProvisioningId(), this.props.job.id);

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    await this.jobQuestionItems.loadListItems();
  }

  componentDidMount() {
    super.componentDidMount();
    this.jobQuestionItems.registerObserver(this);
  }

  componentWillUnmount() {
    this.jobQuestionItems.unregisterObserver(this);
    super.componentWillUnmount();
  }

  onItemChanged(item: JobQuestionItem, change: ListItemChange) {
    if (change === ListItemChange.ADDED || change === ListItemChange.REMOVED) {
      this.reload();
    }
  }

  renderContent(): ReactElement {
    return <>
      <ButtonBase style={{position: "absolute", cursor: "default", left: 0, right: 0, top: 0, bottom: 0}}
                  onClick={() => BaseFocusableView.setFocusedView(null)}/>
      <StyledContainer
        style={{position: "relative", gap: PD_XLG, opacity: this.props.job.status === JobStatus.CLOSED ? 0.75 : 1}}>
        <Typography variant="h6" style={{marginTop: PD_XLG}}>Application details</Typography>
        <Typography>We’ll ask applicants to fill out a standard profile including name and contact information. Decide
          what other information you’d like applicants to provide.</Typography>
        <HeaderView
          job={this.props.job}
          updateJob={this.props.updateJob}
          onAddQuestionItemType={(type) => this.onAddQuestionItemType(type)}
        />
        {this.jobQuestionItems.getListItems().map(item => this.renderItem(item))}
        {this.props.job.status === JobStatus.CLOSED
          ? <ButtonBase
            style={{zIndex: 1000, position: "absolute", cursor: "default", left: 0, right: 0, top: 0, bottom: 0}}
            onClick={() => {
              // Do nothing.
            }}/>
          : null}
      </StyledContainer>
    </>;
  }

  private renderItem(item: JobQuestionItem): ReactElement {
    switch (item.type) {
      case JobQuestionItemType.CHOICE:
        return <QuestionItemChoiceView
          job={this.props.job}
          item={item}
          onAddQuestionItemType={type => this.onAddQuestionItemType(type)}
        />;
      case JobQuestionItemType.TEXT:
        return <QuestionItemTextView
          job={this.props.job}
          item={item}
          onAddQuestionItemType={type => this.onAddQuestionItemType(type)}
        />;
    }
    return null;
  }

  private onAddQuestionItemType(type: JobQuestionItemType): void {
    this.jobQuestionItems.addListItem(JobQuestionItem.createNewItemType(getMemberAuth().getMemberId(), Date.now(), this.props.job.id, type));
  }
}

type JobListingFragmentProps = PageFragmentProps & {
  job: Job,
  updateJob: (job: Job) => void,
}

class JobListingFragment extends PageFragment<JobListingFragmentProps> {

  renderContent(): ReactElement {
    return <StyledContainer>
      <StyledBoxRow style={{alignItems: "center", padding: PD_SM}}>
        <JobStatusView job={this.props.job}/>
        <Typography variant="body2">Created {DateUtil.formatDateTime(this.props.job.created)}</Typography>
        <StyledSpan/>
      </StyledBoxRow>
      <StyledBoxColumn style={{padding: PD_XLG, gap: PD_XLG, marginTop: -24}}>
        <FormGenContainer
          mode={FormGenContainerMode.EDIT}
          autoSave
          onContainerSave={() => this.props.updateJob(this.props.job)}
          content={this.props.job}/>
      </StyledBoxColumn>
    </StyledContainer>
  }
}

enum JobContentTabsType {
  LISTING = "listing",
  APPLICATION = "application",
}

const JOB_CONTENT_TABS_TYPE_KTS = new KeyTextStrings([
  $KTS(JobContentTabsType.LISTING, "Listing"),
  $KTS(JobContentTabsType.APPLICATION, "Application"),
]);

const TABS: TabInfo<JobContentTabsType>[] = [
  {
    type: JobContentTabsType.LISTING,
    text: "Listing",
  },
  {
    type: JobContentTabsType.APPLICATION,
    text: "Application",
  },
];

type JobContentFragmentProps = JobListingFragmentProps & JobApplicationFragmentProps & {}

class JobContentFragment extends PageFragment<JobContentFragmentProps> {

  protected onCreateState(): PageFragmentState {
    return {...super.onCreateState(), selectedToolbarTabId: JobContentTabsType.LISTING};
  }

  protected styleFlags(): number {
    return PageFragment.STYLE_TOOLBAR_TYPE_FLAG;
  }

  getToolbarTabs(): TabInfo<JobContentTabsType>[] {
    return TABS;
  }

  renderContent(): React.ReactElement {
    switch (this.state.selectedToolbarTabId) {
      case JobContentTabsType.LISTING:
        return <JobListingFragment path={this.props.path} job={this.props.job} updateJob={this.props.updateJob}/>;
      case JobContentTabsType.APPLICATION:
        return <JobApplicationFragment path={this.props.path} job={this.props.job} updateJob={this.props.updateJob}/>;
    }
  }
}

export type JobFragmentProps = PageFragmentProps & {
  jobId: string,
}

type JobFragmentState = PageFragmentState & {
  job: Job,
  answerIds?: string[],
}

export class JobFragment extends PageFragment<JobFragmentProps, JobFragmentState> implements PageWithSidebarContainerRenderer, OnListItemsListener<Job> {

  private readonly jobs = Jobs.getInstance();

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    this.setState({
      job: await this.jobs.getOrLoadItem(this.props.jobId),
      answerIds: await new JobAnswerIds(getProvisioningId(), this.props.jobId).getOrLoadListItems(),
    });
  }

  componentDidMount() {
    super.componentDidMount();
    this.jobs.registerObserver(this);
  }

  componentWillUnmount() {
    this.jobs.unregisterObserver(this);
    super.componentWillUnmount();
  }

  onItemChanged(item: Job) {
    if (item.id === this.state.job?.id) {
      this.setState({
        job: item,
      });
    }
  }

  protected styleFlags(): number {
    return PageFragment.STYLE_TOOLBAR_TYPE_FLAG | PageFragment.STYLE_BACK_BUTTON_FLAG;
  }

  protected getToolbar(toolbarModeId: string | null): React.ReactElement {
    const job = this.state.job;
    if (!job) {
      return null;
    }
    const actions: Action[] = [];
    actions.push(new Action("Preview", () => {
      App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN}, (dialogProps) =>
        <ViewJobFragment dialogProps={dialogProps} path={this.props.path} viewMode={ViewMode.PREVIEW} companyId={getProvisioningId()} jobId={job.id}/>);
    }, PreviewOutlined));
    actions.push(...getPublishActions(job));
    actions.push(new Action("Answers", () => {
      App.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN}, (dialogProps) =>
        <ViewJobFragment dialogProps={dialogProps} path={this.props.path} viewMode={ViewMode.ANSWERS} companyId={getProvisioningId()} jobId={job.id}/>);
    }, LeaderboardOutlined).setVariant("contained")
      .setBadgeText("" + (this.state.answerIds?.length ? this.state.answerIds?.length : ""))
      .makeSecondary());

    return <StyledBoxRow style={{flexGrow: 1}}>
      <StyledSpan/>
      {this.renderToolbarButtonsInToolbarContainer(actions)}
    </StyledBoxRow>
  }

  componentDidUpdate(prevProps: Readonly<JobFragmentProps>, prevState: Readonly<JobFragmentState>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if (prevProps.jobId !== this.props.jobId) {
      this.reload();
    }
  }

  private async updateJob(job: Job) {
    this.setState({
      job: job,
    });
    await this.jobs.addListItem(job);
  }

  renderContent(): React.ReactElement {
    const job = this.state.job;
    if (!job) {
      return this.renderNotFound();
    }
    return <PageWithSidebarContainer sidebarLocation={SidebarLocation.RIGHT} renderer={this}/>;
  }

  renderPageWithSidebarContainerContent(): React.ReactElement {
    return <JobContentFragment
      job={this.state.job}
      updateJob={job => this.updateJob(job)}
    />;
  }

  renderPageWithSidebarContainerSidebar(): React.ReactElement {
    const jobs = this.jobs;
    const job = this.state.job;
    const onClose = this.onBackButtonClicked.bind(this);
    return <StyledBoxColumn style={{padding: PD_SM}}>
      {job.status !== JobStatus.CLOSED
        ? Sidebar.renderSidebarCustomCondensed(
          () => <FormGenContainer
            autoSave onContainerSave={container => this.updateJob(job)}
            content={this.state.job.properties}/>,
          "Details")
        : null}
      {job.status !== JobStatus.CLOSED
        ? <Button
          startIcon={<ArchiveOutlined/>}
          onClick={() => {
            function doArchive() {
              job.status = JobStatus.CLOSED;
              jobs.addListItem(job);
              onClose();
            }

            if (Settings.getInstance().general.confirmBeforeDeleting) {
              App.CONTEXT.showTextDialog("Archive", "Are you sure you want to archive \"" + job.title + "\". You cannot undo this action.", new Action("Confirm", () => {
                doArchive();
              }));
            } else {
              doArchive();
            }
          }}
        >Archive</Button>
        : null}
      {job.status === JobStatus.CLOSED
        ? <Typography style={{padding: PD_MD}}>This job has been closed, and cannot be used any more.</Typography>
        : null}
      <Button
        startIcon={<DeleteOutlined/>}
        style={{margin: PD_SM, color: colorRed}}
        onClick={() => {
          function doDelete() {
            jobs.deleteListItemById(job.id);
            onClose();
          }

          if (Settings.getInstance().general.confirmBeforeDeleting) {
            App.CONTEXT.showTextDialog("Delete", "Are you sure you want to delete \"" + job.title + "\". You cannot undo this action.", new Action("Confirm", () => {
              doDelete();
            }));
          } else {
            doDelete();
          }
        }}
      >Delete</Button>
    </StyledBoxColumn>;
  }
}