export class Debouncer {

  private timer: any;

  constructor(readonly timeout: number = 1000) {
  }

  run(fn: () => void) {
    clearTimeout(this.timer);
    this.timer = setTimeout(fn, this.timeout);
  }
}
