import {StyledBoxColumn, StyledBoxRow, StyledSpan} from "../shared/StyledComponents";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  Theme,
  Typography,
  useTheme
} from "@mui/material";
import {colorHighlightAlt, lightGray, white} from "../shared/colors";
import {
  Close,
  ExpandMoreOutlined,
  MenuOutlined,
  MessageOutlined,
  PhoneOutlined,
  StarOutlineOutlined
} from "@mui/icons-material";
import {BORDER_RADIUS, DIVIDER, PD_MD, PD_SM, SZ_SSM} from "../shared/dimens";
import React, {ReactElement, useRef, useState} from "react";
import {CLOUD_STATUS_METADATA, CloudStatus} from "../shared/constants";
import {BaseApp} from "../shared/BaseApp";
import {PathProps} from "../index";
import {THEME_COLOR_PRIMARY} from "./consts";
import {findIcon} from "../shared/icons";
import fyneappsLogotype from "./res/fyneapps_logotype.png";
import {Action, ActionBase} from "../shared/types";

enum DrawerItemType {
  TITLE,
  LINK,
}

type DrawerItem = {
  type: DrawerItemType,
  text: string,
  iconUrl?: string,
  url?: string,
}

const DRAWER_ITEMS: DrawerItem[] = [
  {
    type: DrawerItemType.TITLE,
    text: "Administration",
  },
  {
    type: DrawerItemType.LINK,
    text: "Admin",
    url: "/admin",
    iconUrl: "@icon/admin_panel_settings",
  },
  {
    type: DrawerItemType.LINK,
    text: "Contacts",
    url: "/contacts",
    iconUrl: "@icon/contacts",
  },
  {
    type: DrawerItemType.TITLE,
    text: "Communication",
  },
  {
    type: DrawerItemType.LINK,
    text: "Chat",
    url: "/chat",
    iconUrl: "@icon/chat",
  },
  {
    type: DrawerItemType.LINK,
    text: "Calls",
    url: "/calls",
    iconUrl: "@icon/call",
  },
  {
    type: DrawerItemType.LINK,
    text: "Mail",
    url: "/mail",
    iconUrl: "@icon/mail",
  },
  {
    type: DrawerItemType.TITLE,
    text: "Productivity",
  },
  {
    type: DrawerItemType.LINK,
    text: "Forms",
    url: "/forms",
    iconUrl: "@icon/dynamic_form",
  },
  {
    type: DrawerItemType.LINK,
    text: "Links",
    url: "/links",
    iconUrl: "@icon/bookmarks",
  },
  {
    type: DrawerItemType.LINK,
    text: "Notes",
    url: "/notes",
    iconUrl: "@icon/sticky_note",
  },
  {
    type: DrawerItemType.LINK,
    text: "Tasks",
    url: "/tasks",
    iconUrl: "@icon/task",
  },
  {
    type: DrawerItemType.LINK,
    text: "Todos",
    url: "/todos",
    iconUrl: "@icon/check",
  },
  {
    type: DrawerItemType.LINK,
    text: "Whiteboard",
    url: "/whiteboard",
    iconUrl: "@icon/shape_line",
  },
  {
    type: DrawerItemType.TITLE,
    text: "Scheduling",
  },
  {
    type: DrawerItemType.LINK,
    text: "Calendar",
    url: "/calendar",
    iconUrl: "@icon/calendar",
  },
  {
    type: DrawerItemType.TITLE,
    text: "Storage",
  },
  {
    type: DrawerItemType.LINK,
    text: "Files",
    url: "/files",
    iconUrl: "@icon/cabinet",
  },
];

function renderIcon(iconUrl: string, theme: Theme): ReactElement {
  if (iconUrl?.endsWith(".png")) {
    return <img src={iconUrl} style={{width: 28, height: 28, borderRadius: BORDER_RADIUS}}/>;
  } else if (iconUrl?.startsWith("@icon/")) {
    const IconType = findIcon(iconUrl.substring("@icon/".length));
    return <IconType style={{
      width: 28,
      height: 28,
      alignSelf: "center",
      borderRadius: BORDER_RADIUS,
      backgroundColor: theme.palette.primary.main,
      color: "white"
    }} viewBox="-6 -6 36 36"/>
  }
  return null;
}

const QUICK_ACCESS_ACTIONS: ActionBase[] = [
  new Action("Tasks", () => {
  }, findIcon("task")),
];

export function FyneappsToolbar(props: { path: PathProps, cloudStatus?: CloudStatus }) {
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>();
  const statusMetadata = props.cloudStatus ? CLOUD_STATUS_METADATA.find(metadata => metadata.type === props.cloudStatus) || CLOUD_STATUS_METADATA[0] : null;
  return <>
    <StyledBoxRow
      style={{height: 32, paddingLeft: 4, paddingRight: 4, alignItems: "center", background: colorHighlightAlt}}>
      <Button
        ref={buttonRef}
        style={{paddingTop: 0, paddingBottom: 0, background: white, borderRadius: 0}} startIcon={<MenuOutlined/>}
        onClick={() => {
          setDrawerOpen(true);
        }}
      >
        <StyledBoxRow style={{alignItems: "center", color: THEME_COLOR_PRIMARY, borderRadius: BORDER_RADIUS}}>
          <img src={fyneappsLogotype} style={{height: 24}}/>
          <Typography style={{fontWeight: "bold"}}>{BaseApp.CONTEXT.getAppConfig().name}</Typography>
        </StyledBoxRow>
      </Button>
      {statusMetadata
        ? <StyledBoxRow style={{padding: PD_SM, alignItems: "flex-end"}}>
          <statusMetadata.iconType/>
          <Typography variant="caption">{statusMetadata.text}</Typography>
        </StyledBoxRow>
        : null}
      <StyledSpan/>
      <Button style={{height: 24}} startIcon={<PhoneOutlined/>}>Call</Button>
      <Button style={{height: 24}} startIcon={<MessageOutlined/>}>Chat</Button>
      <Button
        style={{height: 24}} endIcon={<ExpandMoreOutlined/>}
        onClick={(event) => BaseApp.CONTEXT.showActionsListPopover(event.target as HTMLElement, null, QUICK_ACCESS_ACTIONS)}>
        Quick Access
      </Button>
      <IconButton>
        <StarOutlineOutlined/>
      </IconButton>
    </StyledBoxRow>
    <Drawer open={drawerOpen} anchor="left" onClose={() => setDrawerOpen(false)}>
      <Box sx={{width: 320}} role="presentation" onClick={() => setDrawerOpen(false)}>
        <StyledBoxColumn style={{gap: PD_MD}}>
          <StyledBoxRow
            style={{alignItems: "center", padding: PD_MD, marginBottom: -24}}>
            <img src={fyneappsLogotype} style={{height: SZ_SSM}}/>
            <StyledSpan/>
            <IconButton style={{
              width: SZ_SSM,
              height: SZ_SSM,
              background: lightGray,
            }} onClick={() => setDrawerOpen(false)}>
              <Close style={{color: "black"}}/>
            </IconButton>
          </StyledBoxRow>
          <List>
            {DRAWER_ITEMS.map(item => {
              switch (item.type) {
                case DrawerItemType.TITLE:
                  return <Typography style={{
                    borderTop: DIVIDER,
                    marginBottom: -8,
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    padding: PD_MD,
                    color: theme.palette.primary.main
                  }}>{item.text}</Typography>
                case DrawerItemType.LINK:
                  return <ListItem disablePadding>
                    <ListItemButton component={Link} target="_blank" href={item.url}>
                      <StyledBoxRow style={{paddingLeft: PD_MD, height: 32, alignItems: "center"}}>
                        {renderIcon(item.iconUrl, theme)}
                        <Typography variant="body1" style={{fontWeight: "normal"}}>{item.text}</Typography>
                      </StyledBoxRow>
                    </ListItemButton>
                  </ListItem>;
              }
              return null;
            })}
          </List>
        </StyledBoxColumn>
      </Box>
    </Drawer>
  </>;
}