import {Job, JOB_STATUS_KTM_COLORS, JOB_STATUS_KTS, Jobs, JobStatus} from "./types";
import {TableData} from "../../shared/tabledata";
import {BaseSelectTableDataRow, TableDataContainer, TableDataSource} from "../../shared/TableDataContainer";
import {FabSpec, PageFragment} from "../../shared/PageFragment";
import {TableDataSourceFragmentProps} from "../../shared/TableDataSourceFragment";
import React, {ReactElement} from "react";
import {DW_MD, SZ_XLG} from "../../shared/dimens";
import {EditJobHelper, onManageJob} from "./EditJobHelper";
import {PathComponent, PathProps} from "../../index";
import {JobFragment} from "./JobFragment";
import {
  BaseJobListTableDataSourceFragment,
  BaseJobListTableDataSourceFragmentState
} from "../BaseJobListTableDataSourceFragment";
import {Action, ActionBase} from "../../shared/types";
import {AddOutlined, EditOutlined, LaunchOutlined, LinkOutlined} from "@mui/icons-material";
import {Debouncer} from "../../shared/file_util";
import {StyledBoxRow, StyledSpan} from "../../shared/StyledComponents";
import {App} from "../App";
import {FormGenContainer} from "../../shared/FormGenContainer";
import {CompanyProfile} from "../settings/settings";
import {CustomDialogContent} from "../../shared/Dialogs";

class JobsTableDataRow extends BaseSelectTableDataRow {

  @TableData({name: "Title", type: "tagged_text"})
  title: string;

  @TableData({
    name: "Status",
    type: "enum",
    enumValues: JOB_STATUS_KTS.values,
    enumColors: JOB_STATUS_KTM_COLORS.values,
    cellStyle: {width: SZ_XLG}
  })
  status: string;
}

class JobsTableDataSource implements TableDataSource<Job, JobsTableDataRow> {

  private readonly debouncer = new Debouncer(1000);
  private readonly saveItems = new Map<string, Job>();

  constructor(private readonly pathProps: PathProps) {
  }

  createTableDataRow(): JobsTableDataRow {
    return new JobsTableDataRow();
  }

  applyTableDataToRow(data: Job, content: JobsTableDataRow): void {
    content.title = data.title;
    content.status = data.status;
  }

  onTableDataSelected(data: Job) {
    onManageJob(this.pathProps, data);
  }

  onTableCellChanged(data: Job, cellId: string, value: any): void {
    // switch (cellId) {
    //   case "favorite":
    //     data.favorite = value as boolean;
    //     break;
    // }
    // this.saveItems.set(data.id, data);
    // this.debouncer.run(() => {
    //   const jobs = Jobs.getInstance();
    //   this.saveItems.forEach(value => {
    //     jobs.addListItem(value);
    //   });
    //   this.saveItems.clear();
    // })
  }
}

export type JobsFragmentProps = TableDataSourceFragmentProps & {}

type JobsFragmentState = BaseJobListTableDataSourceFragmentState & {
  data: Job[],
  showClosed?: boolean,
}

export class JobsFragment extends BaseJobListTableDataSourceFragment<Job, JobsTableDataRow, Jobs, JobsFragmentProps, JobsFragmentState> {

  private readonly dataSource = new JobsTableDataSource(this.props.path);

  constructor(props: JobsFragmentProps, context: any) {
    super(props, context, Jobs.getInstance());
  }

  protected async fetchOnMount(): Promise<void> {
    await super.fetchOnMount();
    await this.update();
  }

  protected getTitle(): string | null | undefined {
    return "Board Listings";
  }

  onItemChanged(item: Job) {
    this.update();
  }

  componentDidUpdate(prevProps: Readonly<JobsFragmentProps>, prevState: Readonly<JobsFragmentState>, snapshot?: any) {
    if (prevProps !== this.props) {
      this.update();
    }
  }

  private async update() {
    let data = this.loader.getListItems()
      .filter(item => this.state.showClosed || (item.status !== JobStatus.FILLED && item.status !== JobStatus.CLOSED))
    this.setState({
      data: data,
    });
  }

  static nestedPaths(): PathComponent[] {
    return [
      {
        path: ":job_id",
        handle: {containerId: "jobs", path: ":job_id"},
        render: pathProps => <JobFragment path={pathProps} jobId={pathProps.params.job_id}/>,
      },
    ];
  }

  protected getContainerId(): string | null | undefined {
    return "jobs";
  }

  protected styleFlags(): number {
    return PageFragment.STYLE_TOOLBAR_TYPE_FLAG;
  }

  private readonly jobsActions: ActionBase[] = [
    new Action("Edit company profile", () => this.showEditCompanyProfileDialog(), EditOutlined),
    new Action("Share", () => {
    }, LinkOutlined),
    new Action("View", () => {
    }, LaunchOutlined),
  ];

  protected getToolbar(toolbarModeId: string | null): React.ReactElement {
    return <StyledBoxRow style={{flexGrow: 1}}>
      <StyledSpan/>
      {this.renderToolbarButtonsInToolbarContainer(this.jobsActions)}
    </StyledBoxRow>
  }

  createFabSpec(): FabSpec {
    return {
      iconType: AddOutlined,
      variant: "extended",
      text: "New job",
      onClick: () => EditJobHelper.addJob(),
    };
  }

  renderContent(): ReactElement {
    return <TableDataContainer
      data={this.state.data}
      source={this.dataSource}
      multiSelectListener={this}/>;
  }

  private async showEditCompanyProfileDialog() {
    const companyProfile = await CompanyProfile.load();
    App.CONTEXT.showDialog(null,
      () => <CustomDialogContent
        style={{width: DW_MD}}
        title={"Company profile"}
        customView={<FormGenContainer content={companyProfile}/>}
        action={new Action("Save", () => companyProfile.save())}/>);
  }
}