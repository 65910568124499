import React, {ReactElement, useState} from "react";
import {StyledBoxColumn, StyledBoxRow, StyledSpan} from "./StyledComponents";
import {Box, Button, TextareaAutosize, Typography} from "@mui/material";
import {PD_SM, SZ_LG} from "./dimens";
import {FormGenContentItem, FormGenLayoutSource} from "./FormGenContainer";
import Markdown from "react-markdown";

export function FormGenRichText(props: {
  disabled?: boolean,
  contentHeight: number,
  contentItem: FormGenContentItem,
  index: number,
  source: FormGenLayoutSource
}): ReactElement {
  const [preview, setPreview] = useState<boolean>(false);
  const source = props.source;
  const contentItem = props.contentItem;
  return <StyledBoxColumn>
    <StyledBoxRow style={{marginTop: -32}}>
      <StyledSpan/>
      <Button variant={preview ? "contained" : "text"} onClick={() => setPreview(!preview)}>Preview</Button>
    </StyledBoxRow>
    <Box display="flex" flexDirection="row" style={{gap: PD_SM}}>
      {preview
        ? <StyledBoxColumn style={{height: props.contentHeight, overflowY: "scroll"}}>
          <Typography><Markdown>{source.getValue(contentItem)}</Markdown></Typography>
        </StyledBoxColumn>
        : <TextareaAutosize
          disabled={props.disabled}
          style={{
            width: SZ_LG,
            height: props.contentHeight,
            flexGrow: 1,
            resize: "vertical",
            overflow: "auto",
            fontFamily: "sans-serif",
            fontSize: "100%",
            padding: PD_SM
          }}
          value={source.getValue(contentItem) || ""}
          onChange={event => source.setValue(contentItem, event.target.value)}
          id={contentItem.id}
          required/>}
    </Box>
  </StyledBoxColumn>;
}