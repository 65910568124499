import {Typography} from "@mui/material";
import {BORDER_RADIUS, PD_XLG, PD_XSM} from "../shared/dimens";
import {Job, JOB_STATUS_KTM_COLORS, JOB_STATUS_KTS} from "./jobs/types";
import React from "react";

export function JobStatusView(props: { job: Job }) {
  return <Typography style={{
    overflowX: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    paddingTop: PD_XSM,
    paddingBottom: PD_XSM,
    paddingLeft: PD_XLG,
    paddingRight: PD_XLG,
    color: JOB_STATUS_KTM_COLORS.findMetadata(props.job.status)?.color,
    background: JOB_STATUS_KTM_COLORS.findMetadata(props.job.status)?.background,
    borderRadius: BORDER_RADIUS,
  }}>
    {JOB_STATUS_KTS.findText(props.job.status)}
  </Typography>

}