import {FyneappsAppPrefs} from "../fyneapps-shared/FyneappsAppPrefs";

export class AppPrefs extends FyneappsAppPrefs {

  private static instance: AppPrefs;

  static getInstance() {
    if (!this.instance) {
      this.instance = new AppPrefs();
    }
    return this.instance;
  }
}

