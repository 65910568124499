import React, {ReactElement} from 'react';
import {PathComponent, PathProps} from "../index";
import {TabInfo} from "../shared/TabsContainer";
import {SettingsOutlined, TableRowsOutlined} from "@mui/icons-material";
import {JobsFragment} from "./jobs/JobsFragment";
import {FyneappsApp, FyneappsMain} from "../fyneapps-shared/FyneappsApp";
import {SettingsFragment} from "./settings/SettingsFragment";
import {BaseAppPrefs} from "../shared/BaseAppPrefs";
import {AppPrefs} from "./app_prefs";
import {User} from "@firebase/auth";
import {Settings} from "./settings/settings";
import {LoginConfig} from "../shared/Login";
import {ViewJobFragment} from "./jobs/ViewJobFragment";
import {Outlet} from "react-router-dom";
import {ViewJobsFragment} from "./jobs/ViewJobsFragment";
import {ViewMode} from "./jobs/BaseViewFragment";

export enum TabType {
  JOBS = "jobs",
  SETTINGS = "settings",
}

class Main extends FyneappsMain {

  static appTabs(): TabInfo<string>[] {
    return [
      {
        type: TabType.JOBS,
        path: "jobs",
        text: "Jobs",
        iconType: TableRowsOutlined,
        nestedPaths: JobsFragment.nestedPaths(),
        render: (path: PathProps) => <JobsFragment path={path}/>,
      },
      {
        type: TabType.SETTINGS,
        path: "settings",
        text: "Settings",
        iconType: SettingsOutlined,
        nestedPaths: SettingsFragment.nestedPaths(),
        render: (path: PathProps) => <SettingsFragment path={path}/>,
      },
    ];
  }
}

export class App extends FyneappsApp {

  getAppPrefs<T extends BaseAppPrefs>(): T {
    //@ts-ignore
    return AppPrefs.getInstance() as T;
  }

  protected getLoginConfig(): LoginConfig | null | undefined {
    return {
      ...super.getLoginConfig(),
      noLogin: true,
    }
  }

  protected async onAppInitWithLoggedInUser(user: User): Promise<void> {
    await super.onAppInitWithLoggedInUser(user);
    await Settings.loadInstance();
  }

  protected getAppName(): string {
    return "Jobs";
  }

  protected getIconName(): string {
    return "@icon/business_center";
  }

  static nestedPaths(): PathComponent[] {
    return [
      ...Main.nestedPaths(),
      {
        path: "v/:company_id",
        handle: {containerId: "viewer"},
        render: pathProps => <ViewJobsFragment path={pathProps} viewMode={ViewMode.INPUT}/>,
      },
      {
        path: "v/:company_id/:job_id",
        handle: {containerId: "viewer"},
        render: pathProps => <ViewJobFragment path={pathProps} viewMode={ViewMode.INPUT}/>,
      },
    ];
  }

  protected renderMain(): ReactElement {
    if (this.props.path?.matches.find(match => match.handle?.containerId === "viewer")) {
      return <Outlet/>;
    }
    if (!this.hasAuthCredentials()) {
      return this.renderLogin();
    }
    return <Main path={this.props.path}/>;
  }
}
