import {DialogFragment, DialogFragmentProps, DialogFragmentState, PageFragment} from "../../shared/PageFragment";
import React, {ReactElement} from "react";
import {StyledBoxColumn, StyledBoxRow, StyledContainer, StyledSpan} from "../../shared/StyledComponents";
import {Card, Typography} from "@mui/material";
import {PD_MD, PD_XLG, PD_XXLG, SZ_JUMBO, SZ_SSM} from "../../shared/dimens";
import {BaseApp, DIALOG_FLAG_DISABLE_BACKDROP_CLICK, DIALOG_FLAG_SHOW_CLOSE} from "../../shared/BaseApp";
import {HtmlFragment} from "../../shared/HtmlFragment";
import {AboutFragment} from "../../shared/AboutFragment";
import fyneappsLogotype from "fyneapps-shared/res/fyneapps_logotype.png";
import {CompanyProfile} from "../settings/settings";
import Markdown from "react-markdown";
import {App} from "../App";
import {Action, ActionBase, ActionGroup, ActionGroupMode} from "../../shared/types";
import {ComputerOutlined, PhoneOutlined} from "@mui/icons-material";
import {JobStatusView} from "../JobStatusView";
import {ProvisioningContext} from "../../shared/database";

export enum ViewMode {
  INPUT,
  PREVIEW,
  ANSWERS,
}

enum PreviewAs {
  COMPUTER,
  MOBILE,
}

export type BaseViewFragmentProps = DialogFragmentProps & {
  viewMode: ViewMode,
  companyId?: string,
}

export type BaseViewFragmentState = DialogFragmentState & {
  companyId: string,
  companySettings: CompanyProfile,
  viewAs: PreviewAs,
}

export abstract class BaseViewFragment<P extends BaseViewFragmentProps, S extends BaseViewFragmentState> extends DialogFragment<P, S> {

  protected onCreateState(): S {
    return {
      ...super.onCreateState(),
      viewAs: PreviewAs.COMPUTER,
    };
  }

  protected styleFlags(): number {
    if (this.props.viewMode === ViewMode.INPUT) {
      return 0;
    }
    return PageFragment.STYLE_TOOLBAR_TYPE_FLAG | PageFragment.STYLE_BACK_BUTTON_FLAG;
  }

  onBackButtonClicked() {
    App.CONTEXT.hideAllDialogs();
  }

  private readonly previewModeActions: ActionBase[] = [
    new ActionGroup([
        new Action("Computer", () => {
          this.setState({
            viewAs: PreviewAs.COMPUTER,
          });
        }, ComputerOutlined),
        new Action("Mobile", () => {
          this.setState({
            viewAs: PreviewAs.MOBILE,
          });
        }, PhoneOutlined),
      ],
      "View as",
      ActionGroupMode.SINGLE_SELECT_AUTO),
  ];

  protected getToolbar(toolbarModeId: string | null): React.ReactElement {
    switch (this.props.viewMode) {
      default:
      case ViewMode.INPUT:
        return this.renderToolbarInput();
      case ViewMode.PREVIEW:
        return <StyledBoxRow style={{flexGrow: 1}}>
          {this.renderToolbarButtonsInToolbarContainer(this.previewModeActions)}
          {this.renderToolbarPreview()}
        </StyledBoxRow>;
      case ViewMode.ANSWERS:
        return <StyledBoxRow style={{flexGrow: 1}}>
          {this.renderToolbarAnswers()}
        </StyledBoxRow>;
    }
  }

  protected renderToolbarInput(): ReactElement {
    return null;
  }

  protected renderToolbarPreview(): ReactElement {
    return null;
  }

  protected renderToolbarAnswers(): ReactElement {
    return null;
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    const companyId = this.props.companyId || this.props.path.params.company_id;
    if (!companyId) {
      return;
    }
    const companySettings = await CompanyProfile.load(new ProvisioningContext(companyId));
    this.setState({
      companyId: companyId,
      companySettings: companySettings.isLoaded() ? companySettings : null,
    });
  }

  renderContent(): ReactElement {
    const companySettings = this.state.companySettings;
    if (!companySettings) {
      return this.renderNotFound();
    }
    const viewSmall = this.state.viewAs === PreviewAs.MOBILE;
    return <StyledContainer size={viewSmall ? "xsm" : undefined} style={{
      zIndex: 1,
      gap: PD_XLG,
      paddingTop: this.props.viewMode !== ViewMode.ANSWERS ? PD_XXLG : PD_MD,
      paddingBottom: PD_XXLG
    }}>
      {!this.props.path.location.search?.includes("embed=1")
        ? <StyledBoxColumn style={{alignItems: "flex-start"}}>
          {companySettings.logo
            ? <img src={companySettings.logo} style={{height: 96, objectFit: "contain"}}/>
            : null}
          <Typography variant="h4">{companySettings.name}</Typography>
          <Typography><Markdown>{companySettings.about}</Markdown></Typography>
        </StyledBoxColumn>
        : null}
      <Card>
        <StyledBoxColumn>
          <StyledBoxColumn style={{padding: PD_XLG, borderTop: companySettings.themeColor + " 8px solid"}}>
            {this.renderContentHead()}
          </StyledBoxColumn>
          <StyledBoxColumn style={{minHeight: SZ_JUMBO, marginTop: -24, gap: PD_XLG}}>
            {this.renderContentBody()}
          </StyledBoxColumn>
        </StyledBoxColumn>
      </Card>
      <a href="https://jobs.fyneapps.com" style={{alignSelf: "center"}}>
        <StyledBoxRow style={{alignItems: "center", gap: 0, opacity: 0.33}}>
          <img src={fyneappsLogotype} style={{height: SZ_SSM, marginBottom: -4}}/>
          <Typography style={{fontSize: "125%", fontWeight: 600}}>Jobs</Typography>
        </StyledBoxRow>
      </a>
      <Typography variant="body2">This content is created by the owner of this job board. The data you submit will be
        sent to them. Fyneapps is not responsible for the privacy or security practices of its customers, including
        those of the owner of this job board.</Typography>
      <StyledBoxRow style={{alignItems: "center", gap: PD_XLG, padding: PD_MD, justifyContent: "center"}}>
        <Typography variant="caption">
          <a href="#"
             onClick={() => BaseApp.CONTEXT.showDialog(null, () =>
               <HtmlFragment
                 url="/privacy.html"/>)}>
            Privacy Policy
          </a>
        </Typography>
        <Typography variant="caption">
          <a href="#"
             onClick={() => BaseApp.CONTEXT.showDialog(null, () =>
               <HtmlFragment
                 url="/terms.html"/>)}>
            Terms
          </a>
        </Typography>
        <Typography variant="caption">
          <a href="#"
             onClick={() => BaseApp.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_DISABLE_BACKDROP_CLICK}, () =>
               <AboutFragment/>)}>
            About
          </a>
        </Typography>
      </StyledBoxRow>
    </StyledContainer>;
  }

  protected abstract renderContentHead(): ReactElement;

  protected abstract renderContentBody(): ReactElement;
}