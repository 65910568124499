import {FileUploadOptions, KeyTextString} from "./types";
import {FormGenFreetextType} from "./FormGenAutocomplete";
import {ReactElement} from "react";
import {PathProps} from "../index";

export const FORMGEN_STYLE_FLAG_NO_CONTAINER = 0x1 << 1;

export interface FormGenMetadata {
  index?: number,
  // Note that "object" type is for nested objects, "custom" is when you want to supply your own renderer.
  type: "boolean" | "string" | "text" | "rich_text" | "copyable" | "email" | "number" | "enum" | "date" | "time" | "datetime" | "datenum" | "timenum" | "datetimenum" | "object" | "custom" | "profile_photo" | "icon" | "color" | "autocomplete" | "country_select" | "file" | "array_string" | "array_autocomplete" | "array_file";
  styleFlags?: number,
  maxLength?: number,
  multilineStringRows?: number,
  size?: "small" | "medium" | "large";
  ref?: string;
  name: string;
  readonly?: boolean;
  readonlyFn?: (target: any) => boolean;
  noneditable?: boolean;
  separator?: "above" | "below";
  sectionTitle?: string;
  description?: string;
  visibility?: string;

  // String'ish only
  transformText?: ((str: string) => string) | "uppercase" | "lowercase",

  // File only
  fileMimeTypes?: string[],
  fileUploadOptions?: FileUploadOptions,
  fileStoredDataSource?: string,

  // Enum only
  enumDefaultKey?: string,
  enumVariant?: "select" | "button",
  enumValues?: KeyTextString[],
  enumDescriptions?: KeyTextString[],
  enumMulti?: boolean,

  // Autocomplete only
  autocompleteResetOnChange?: boolean,
  autocompleteMulti?: boolean,
  autocompleteFreetextType?: FormGenFreetextType,
  autocompleteLoader?: (value?: string, inputText?: string) => Promise<KeyTextString[]>,

  // Custom only
  customRenderer?: (target: any, setValue: (value: any) => void, path?: PathProps) => ReactElement,

  onSetValue?: (target: any, value: any) => void, // Before value is set.
  onSetTransformValue?: (target: any, value: any) => any,
  onValueSet?: (target: any, value: any) => void, // After value is set.

  _asyncInit?: (metadata: FormGenMetadata) => Promise<void>,
  _propertyKey?: string,
}

export const formGenMetadataKey = (propertyKey: string) => "__formgen_metadata_" + propertyKey;

export function FormGen(metadata: FormGenMetadata) {
  return function (target: any, propertyKey: string) {
    metadata._propertyKey = propertyKey;
    target[formGenMetadataKey(propertyKey)] = metadata;
    if (metadata.enumValues) {
      target[propertyKey + "__enumValues"] = metadata.enumValues;
    }
  };
}

export const enumValue = <T>(obj: T, propName: keyof T) => {
  const array = obj[propName.toString() + "__enumValues"];
  const key = obj[propName];
  return array.find(item => item.key === key)?.text;
}

export abstract class FormGenType<T> {

  protected constructor(readonly model?: T) {
  }

  abstract toModel(): T;
}
